import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MidoLogo from '../assets/logo.svg';
import Button from '@mui/material/Button';
import { useAuth0 } from "@auth0/auth0-react";
// import { Link } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { colors } from '../utils';
import { lighten, darken } from '@mui/material/styles';


export default function PublicHeader() {
    const { loginWithRedirect } = useAuth0();
    const theme = useTheme();
    const big = useMediaQuery(theme.breakpoints.up('md'));

    // const handleSignUp = async () => {
    //     await loginWithRedirect({
    //         appState: {
    //             returnTo: "/",
    //         },
    //         authorizationParams: {
    //             prompt: "login",
    //             screen_hint: "signup",
    //         },
    //     });
    // };

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/",
            },
            authorizationParams: {
                prompt: "login",
            },
        });
    };

    return (
        <AppBar
            position="static"
            color="primary"
            elevation={5}
            sx={{ borderBottom: (theme) => `1sp solid ${theme.palette.divider}` }}
        >
            <Toolbar sx={{ flexWrap: 'wrap' }}>
                <Typography variant="h6" color="inherit" align='left' noWrap sx={{ flexGrow: 1 }}>
                    <img src={MidoLogo} alt="MIDO Logo" width={big ? "200dp" : "100dp"} />
                </Typography>
                <nav>
                    {/* <Link
                        variant="button"
                        href="/features"
                        sx={{ my: 1, mx: 1.5, color: colors['GhostWhite'] }}
                    >
                        Features
                    </Link>
                    <Link
                        variant="button"
                        href="mailto:hellomido@mido-app.com"
                        sx={{ my: 1, mx: 1.5, color: colors['GhostWhite'] }}
                    >
                        Support
                    </Link>
                    <Link
                        variant="button"
                        href="/pricing"
                        sx={{ my: 1, mx: 1.5, color: colors['GhostWhite'] }}
                    >
                        Pricing
                    </Link> */}
                </nav>
                {/* <Button href="#" variant="contained" onClick={handleSignUp} sx={{ my: 1, mx: 1.5 }}>
                    Sign Up For Free
                </Button> */}
                <Button onClick={handleLogin} variant="contained" sx={{
                            my: 1,
                            mx: 1.5,
                            backgroundColor: colors.Nepal,
                            color: colors.Jet,
                            ":hover": {
                                color: darken(colors.Jet, 0.5),
                                backgroundColor: lighten(colors.Nepal, 0.4),
                            }
                        }}>
                    Login
                </Button>
            </Toolbar>
        </AppBar>
    );
};
