import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { colors } from '../utils';

interface Feature {
    name: string;
    description: string;
}


function BasicCard(feature: Feature) {
    return (
        <Grid item xs={12} md={6}>
            <Card key={feature.name} sx={{
                textAlign: 'left',
                color: colors['GhostWhite'],
                backgroundColor: colors.Nepal,
            }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        {feature.name}
                    </Typography>
                    <Typography variant="body2">
                        {feature.description}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" variant='contained' sx={{
                        color: colors['GhostWhite'],
                        borderColor: colors['GhostWhite'],
                        backgroundColor: colors.Mirage,
                    }}>Learn More</Button>
                </CardActions>
            </Card>
        </Grid>
    );
}


const features = [
    {
        name: "Reminders with Context",
        description: "Create reminders and save links or small files for context",
    },
    {
        name: "Multiple Alerts",
        description: "Each reminder can have multiple rules to alert on based on date information provided in the reminder",
    },
]

export default function Features() {
    return (
        <React.Fragment>
            <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 6, pb: 2 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                >
                    MIDO Features
                </Typography>

                <Grid
                    spacing={2}
                    container
                >
                    {features.map(BasicCard)}
                </Grid>
            </Container>
        </React.Fragment>
    );
}
