import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams, GridRowParams, GridCallbackDetails, GridToolbarContainer } from '@mui/x-data-grid';
import { MuiEvent } from '@mui/x-data-grid';
import moment from 'moment';
import { Issue } from '../models/issues';
import IssueDetails from './issue-dialog';
import { listTeamIssues } from '../services/issues';
import { useAuth0 } from '@auth0/auth0-react';
import { CurrentUserContext } from '../contexts/UserProvider';
import { Button, Tooltip } from '@mui/material';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';


const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
        field: 'summary',
        headerName: 'summary',
        flex: 1,
        sortable: true,
    },
    {
        field: 'opened',
        headerName: 'Opened',
        flex: 1,
        sortable: true,
        valueGetter: (params: GridValueGetterParams) => moment(params.row.opened).fromNow()
    },
    {
        field: 'comments',
        headerName: 'Comments',
        description: 'Number of comments',
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.comments.length}`,
    },
];


export default function IssueList() {
    const userContext = React.useContext(CurrentUserContext);
    const { getAccessTokenSilently } = useAuth0();
    const [issues, setIssues] = React.useState<Issue[]>([]);
    const [detailDialogShow, setDetailDialogShow] = React.useState(false);
    const [issue, setIssue] = React.useState<Issue | null>(null);

    React.useMemo(async () => {
        if (!userContext || !userContext.currentTeam) return;
        const token = await getAccessTokenSilently();
        const newIssues = await listTeamIssues(token, userContext!.currentTeam!.team._id)
        if (newIssues.data) {
            setIssues(newIssues.data);
        }
    }, [setIssues, userContext, getAccessTokenSilently])

    const rowEditRequested = (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        console.log(params);
        showDetailDialog(params.row.id);
    };

    const showDetailDialog = React.useCallback((issueId: string | null) => {
        const _issue = issues.find((row) => row.id === issueId);
        if (_issue) {
            setIssue(_issue);
        } else {
            // setError("Issue not found");
            return;
        }
        setDetailDialogShow(true);
    }, [issues]);

    function EditToolbar() {
        const handleClick = () => {
            // showCreateIssue();
        };

        return (
            <GridToolbarContainer sx={{ marginBottom: 1, marginTop: 2 }}>
                <Box sx={{flex: 1}}></Box>
                <Tooltip title="Open Support Issue">
                    <Button color="primary" variant='outlined' startIcon={<SupportTwoToneIcon />} onClick={handleClick}>
                        Open Support Issue
                    </Button>
                </Tooltip>
            </GridToolbarContainer>
        );
    }


    return (
        <div>
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={issues}
                    columns={columns}
                    onRowDoubleClick={rowEditRequested}
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                        },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            }
                        }
                    }}
                    pageSizeOptions={[5]}
                    disableRowSelectionOnClick
                />
            </Box>
            <IssueDetails open={detailDialogShow} setOpen={setDetailDialogShow} issue={issue} setIssue={setIssue} />
        </div>
    );
}