import React from "react";
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { CurrentUserContext } from '../contexts/UserProvider';
import { Link } from 'react-router-dom';
import Button from "@mui/material/Button";
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
import { MemberTeam } from "../models/teams";
import { colors } from "../utils";


export const TeamSelector: React.FC = () => {
    const userContext = React.useContext(CurrentUserContext);
    const [teams, setTeams] = React.useState<MemberTeam[]>([]);

    const handleTeamChange = (event: SelectChangeEvent) => {
        const teamId = event.target.value;
        if (userContext && userContext.userData && userContext.userData.teams) {
            userContext!.userData!.teams!.forEach((team) => {
                if (team.team._id === teamId) {
                    userContext!.selectTeam(team);
                }
            });
        }
    };

    React.useMemo(() => {
        if (userContext && userContext.userData && userContext.userData.teams) {
            setTeams(userContext.userData.teams);
        }
    }, [userContext, setTeams]);

    return (
        <React.Fragment>
            {(teams && userContext!.currentTeam) ? (
                <React.Fragment>
                    <FormControl fullWidth sx={{ paddingTop: "4dp", padding: "0", backgroundColor: colors["GhostWhite"] }}>
                        <InputLabel id="team-select-label" color="primary">Team</InputLabel>
                        <Select
                            labelId="team-select-label"
                            id="team-select"
                            key={userContext!.currentTeam.team.name}
                            value={userContext!.currentTeam.team._id}
                            label="Team"
                            onChange={handleTeamChange}
                        >
                            {teams.map((team, idx) => (
                                <MenuItem
                                    key={idx}
                                    value={team.team._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {team.team.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </React.Fragment>
            ) : (
                <Link to="/teams">
                    <Button variant="contained" startIcon={<GroupAddTwoToneIcon />}>
                        Create a Team
                    </Button>
                </Link>
            )}
        </React.Fragment>
    );
};
