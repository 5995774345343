export interface User {
    _id: string | null;
    name: string | null;
    email: string | null;
    nickname: string | null;
    deleted: boolean | null;
    marketing_emails: boolean | null;
    news_emails: boolean | null;
    full_name: string | null;
    first_login: boolean | null;
    email_verified: boolean | null;
    created: string | null;
    last_modified: string | null;
}

export const MIDO_ID = "000000000000000000000001";

export interface Team {
    _id: string;
    name: string;
    created: string;
    last_modified: string | null;
    plan: string;
    timezone: string;
}

export interface UpdateTeam {
    _id: string;
    name: string;
    timezone: string;
}

enum MembershipType {
    member = "member",
    admin = "admin",
    superuser = "superuser",
}

export interface TeamMember {
    user: User;
    created: string;
    last_modified: string | null;
    type: MembershipType;
}

export interface MemberTeam {
    team: Team;
    created: string;
    last_modified: string | null;
    type: MembershipType;
}

export interface CurrentUserData {
    user: User | null;
    teams: [MemberTeam] | null;
}