import React from "react";
import { TeamRemindersContext } from "../contexts/RemindersProvider";
import { SubmitButton } from "../components/submit-button";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { RuleSet, Match, NotifyAction } from "../models/reminders";
import { ContactsComboBox, ContactDropdownItem } from "../components/contacts-combobox";
import AddCommentTwoToneIcon from '@mui/icons-material/AddCommentTwoTone';
import {
    Alert, AlertTitle, Box, Collapse, FormGroup,
    FormLabel, IconButton, InputLabel, Paper, Stack,
    TextField, Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AddBoxTwoTone } from "@mui/icons-material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { enumValues } from "../utils";
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import { ChangeResponse } from "../models/base";


type ruleSetDetailProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    ruleSet: RuleSet;
    setRuleSet: React.Dispatch<React.SetStateAction<RuleSet>>;
};


export default function RuleSetDetails({ open, setOpen, ruleSet, setRuleSet }: ruleSetDetailProps) {
    const [loading, setLoading] = React.useState(false);
    const [submitDisabled, setSubmitDisabled] = React.useState(true);
    const [showError, setShowError] = React.useState(false);
    const [error, setError] = React.useState("");
    const teamContext = React.useContext(TeamRemindersContext);
    const [contacts, setContacts] = React.useState<ContactDropdownItem[]>([]);



    const setName = React.useCallback((name: string) => {
        setRuleSet(
            {
                ...ruleSet,
                name: name,
            }
        )
        setSubmitDisabled(false);
    }, [ruleSet, setRuleSet]);

    const handleFormSubmit = React.useCallback(async () => {
        setLoading(true);
        const response = await teamContext!.saveRuleSet(ruleSet);
        if (response.data) {
            setLoading(false);
            setSubmitDisabled(true);
            setOpen(false);
        } else {
            setError(response.error!.message as string);
            setShowError(true);
            setLoading(false);
        }
    }, [ruleSet, setOpen, teamContext]);

    const handleMatchChange = (event: SelectChangeEvent) => {
        setRuleSet({
            ...ruleSet,
            match: event.target.value as Match,
        })
        setSubmitDisabled(false);
    };

    const handleClose = () => {
        setOpen(false);
        setLoading(false);
    };

    const addRule = React.useCallback(() => {
        if (ruleSet.rules) {
            setRuleSet(
                {
                    ...ruleSet,
                    rules: [...ruleSet.rules, ""]
                }
            )
        } else {
            setRuleSet(
                {
                    ...ruleSet,
                    rules: [""]
                }
            )
        }
        setSubmitDisabled(false);
    }, [setRuleSet, ruleSet]);

    const setRule = React.useCallback((index: number, rule: string) => {
        var newRules = [...ruleSet.rules!];
        newRules[index] = rule;
        setRuleSet({ ...ruleSet, rules: newRules });
        setSubmitDisabled(false);
    }, [setRuleSet, ruleSet]);

    const removeRule = React.useCallback((index: number) => {
        if (ruleSet) {
            setRuleSet(
                {
                    ...ruleSet,
                    rules: [...ruleSet.rules!.slice(0, index), ...ruleSet.rules!.slice(index + 1)],
                }
            );
        }
        setSubmitDisabled(false);
    }, [setRuleSet, ruleSet]);

    const removeContact = React.useCallback((index: number) => {
        if (ruleSet) {
            var action: NotifyAction = ruleSet.actions && ruleSet.actions.length ? (
                { ...ruleSet.actions[0] }
            ) : ({ name: "notify action", type: "notify", contacts: [] } as NotifyAction);
            if (!action.contacts) {
                action.contacts = [];
            } else {
                action.contacts = [...action.contacts!.slice(0, index), ...action.contacts!.slice(index + 1)];
            }
            setRuleSet(
                {
                    ...ruleSet,
                    actions: [action],
                }
            );
            setSubmitDisabled(false);
        }
        setSubmitDisabled(false);
    }, [setRuleSet, ruleSet]);


    const updateContactValue = React.useCallback((index: number, value?: string): ChangeResponse => {
        setSubmitDisabled(false);
        var action: NotifyAction = ruleSet.actions && ruleSet.actions.length ? (
            { ...ruleSet.actions[0] }
        ) : ({ name: "notify action", type: "notify", contacts: [] } as NotifyAction);
        if (!action.contacts) {
            action.contacts = [];
        }
        if (!value || value === "") {
            action.contacts[index] = "";
            setRuleSet({ ...ruleSet, actions: [action] });
        } else {
            action.contacts[index] = value;
            setRuleSet({ ...ruleSet, actions: [action] });
            if (action.contacts!.find(contact => contact === value)) {
                return { error: "Contact already exists for this ruleSet." } as ChangeResponse;
            }
        }
        return { error: null };
    }, [setRuleSet, ruleSet]);

    const addContact = React.useCallback(() => {
        var action: NotifyAction = ruleSet.actions && ruleSet.actions.length ? (
            { ...ruleSet.actions[0] }
        ) : ({ name: "notify action", type: "notify", contacts: [] } as NotifyAction);
        if (!action.contacts) {
            action.contacts = [""];
        } else {
            action.contacts.push("");
        }
        setSubmitDisabled(false);
        setRuleSet({ ...ruleSet, actions: [action] });
    }, [setRuleSet, ruleSet]);

    React.useMemo(() => {
        if (teamContext && teamContext.data.contacts) {
            const _contacts = teamContext.data.contacts.map((contact): ContactDropdownItem => {
                return {
                    id: contact._id,
                    label: contact.name,
                } as ContactDropdownItem;
            });
            setContacts(_contacts);
        }
    }, [setContacts, teamContext]);

    console.log("render details");


    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{!ruleSet || !ruleSet._id ? "New Ruleset" : "Ruleset Details"}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: 2 }}>
                    Rules are a list of validations on a Remider that will trigger a notification when validated.
                </DialogContentText>
                {/* =================================  GENERAL  ================================= */}
                <Paper elevation={1} sx={{ padding: 3, marginBottom: 1 }}>
                    <Stack spacing={2}>
                        <TextField
                            fullWidth={true}
                            size="small"
                            id="ruleSet-name"
                            label="Name"
                            value={ruleSet.name}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setName(event.target.value);
                            }}
                        />
                        <FormControl fullWidth>
                            <InputLabel id="match-label">Match</InputLabel>
                            <Select
                                fullWidth
                                size="small"
                                labelId="match-label"
                                id="match"
                                onChange={handleMatchChange}
                                value={ruleSet.match}
                                label="Match"
                            >
                                {enumValues(Match).map((value, index) => (<MenuItem key={index} value={value}>{value}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Stack>
                </Paper>
                {/* =================================  CONTACTS  ================================= */}
                <FormControl sx={{ marginTop: 2, ph: 2 }} variant="standard" fullWidth>
                    <FormLabel component="legend">Contacts to Notify</FormLabel>
                    <Box>
                        <Tooltip title="Add contact to ruleSet">
                            <Button onClick={addContact} size="small" aria-label="Add contact to ruleSet" variant="contained" color='primary' endIcon={<AddCommentTwoToneIcon />}>Add</Button>
                        </Tooltip>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                </FormControl>
                <FormControl sx={{ marginTop: 2, pv: 2 }} component="fieldset" variant="standard">
                    <FormGroup>
                        {ruleSet.actions && ruleSet.actions[0].contacts && ruleSet.actions[0].contacts.map((contact, index) => (
                            <Stack key={index + "_" + contact} direction="row" spacing={2}>
                                <ContactsComboBox contacts={contacts} value={contact} setValue={updateContactValue} index={index} />
                                <Tooltip title="Remove contact from ruleSet">
                                    <IconButton aria-label="Remove contact from ruleSet" onClick={() => { removeContact(index); }}>
                                        <HighlightOffTwoToneIcon color='primary' />
                                    </IconButton>
                                </Tooltip>
                            </Stack>

                        ))}
                    </FormGroup>
                </FormControl>
                {/* =================================  RULES  ================================= */}
                <Paper elevation={1} sx={{ padding: 3 }}>
                    <FormLabel component="legend">Rules</FormLabel>
                    <Box>
                        <Tooltip title="Add rule to ruleSet">
                            <Button onClick={addRule} size="small" aria-label="Add rule to ruleset" variant="contained" color='primary' endIcon={<AddBoxTwoTone />}>Add</Button>
                        </Tooltip>
                    </Box>
                    <FormGroup>
                        {ruleSet.rules && ruleSet.rules.map((rule, index) => (
                            <FormControl sx={{ marginTop: 2, pv: 2 }} component="fieldset" variant="standard" key={index}>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        fullWidth={true}
                                        size="small"
                                        id="rule-expression"
                                        label="Expression"
                                        value={rule}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setRule(index, event.target.value);
                                        }}
                                    />
                                    <Tooltip title="Remove rule from ruleSet">
                                        <IconButton aria-label="Remove rule from ruleSet" onClick={() => { removeRule(index); }}>
                                            <HighlightOffTwoToneIcon color='primary' />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </FormControl>
                        ))}
                    </FormGroup>
                </Paper>

                <Collapse in={showError}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowError(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>
                </Collapse>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <SubmitButton
                    label="Save"
                    clickHandler={handleFormSubmit}
                    loading={loading}
                    setLoading={setLoading}
                    disabled={submitDisabled}
                />
            </DialogActions>
        </Dialog>
    );
}


