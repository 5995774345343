

export interface Link {
    name: string;
    url: string;
}


export interface NumericProperty {
    name: string;
    value: number;
}

export interface StringProperty {
    name: string;
    value: string;
}

export interface DateProperty {
    name: string;
    value: Date;
}

export interface Contact {
    _id?: string;
    created?: string;
    last_modified?: string;
    team?: string;
    name: string;
    email: string;
}


export interface Reminder {
    _id?: string;
    created?: string;
    last_modified?: string;
    team?: string;
    name?: string;
    description?: string;
    match: Match;
    links?: Link[];
    rulesets?: string[];
    properties?: (NumericProperty | StringProperty | DateProperty)[]
}

export interface NotifyAction {
    name: string;
    type: string;
    contacts?: string[];
}


export enum Match {
    ALL = "all",
    ANY = "any",
    ONE = "one",
}

export interface RuleSet {
    _id?: string;
    created?: string;
    last_modified?: string;
    actions: NotifyAction[];
    team: string;
    name: string;
    match: Match;
    rules: string[];
}
