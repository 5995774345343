import { AxiosRequestConfig } from "axios";
import { callApi } from "./call-api";
import { ApiResponse, AppError, MessageResponse, ValidationError } from "../models/base";
import { Contact, Reminder, RuleSet } from "../models/reminders";

const apiServerUrl = process.env.REACT_APP_MIDO_API_URL;


export const listTeamReminders = async (accessToken: string, teamId: string): Promise<ApiResponse<Reminder[]>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/teams/${teamId}/mido/reminders`,
        method: "GET",
        timeout: 5000,
        headers: {
            "content-type": "application/json",
            Authorization: `bearer ${accessToken}`,
        },
    };
    return (await callApi({ config })) as ApiResponse<Reminder[]>;
};


export const listTeamContacts = async (accessToken: string, teamId: string): Promise<ApiResponse<Contact[]>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/teams/${teamId}/mido/contacts`,
        method: "GET",
        timeout: 5000,
        headers: {
            "content-type": "application/json",
            Authorization: `bearer ${accessToken}`,
        },
    };
    return (await callApi({ config })) as ApiResponse<Contact[]>;
};



export const listTeamRules = async (accessToken: string, teamId: string): Promise<ApiResponse<RuleSet[]>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/teams/${teamId}/mido/rulesets`,
        method: "GET",
        timeout: 5000,
        headers: {
            "content-type": "application/json",
            Authorization: `bearer ${accessToken}`,
        },
    };

    return (await callApi({ config })) as ApiResponse<RuleSet[]>;
};


export const putContact = async (accessToken: string, teamId: string, contact: Contact): Promise<ApiResponse<Contact>> => {
    contact.team = teamId;
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/teams/${teamId}/mido/contacts`,
        method: "PUT",
        timeout: 5000,
        data: contact,
        headers: {
            "content-type": "application/json",
            Authorization: `bearer ${accessToken}`,
        },
    };

    const { data, error, statusCode } = (await callApi({ config })) as ApiResponse<Contact>;
    console.log(error);
    var _error: AppError | null = error;
    const valEr = error as ValidationError;
    if(valEr) {
        _error = {message: "Validation Error occured"} as AppError;
    }
    return {
        data,
        error: _error,
        statusCode,
    };

}

export const deleteContact = async (accessToken: string, teamId: string, contactId: string): Promise<ApiResponse<MessageResponse>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/teams/${teamId}/mido/contact/${contactId}`,
        method: "DELETE",
        timeout: 5000,
        headers: {
            "content-type": "application/json",
            Authorization: `bearer ${accessToken}`,
        },
    };

    const { data, error, statusCode } = (await callApi({ config })) as ApiResponse<MessageResponse>;
    console.log(error);
    var _error: AppError | null = error;
    const valEr = error as ValidationError;
    if(valEr) {
        _error = {message: "Validation Error occured"} as AppError;
    }
    return {
        data,
        error: _error,
        statusCode,
    };

}


export const putReminder = async (accessToken: string, teamId: string, reminder: Reminder): Promise<ApiResponse<Reminder>> => {
    reminder.team = teamId;
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/teams/${teamId}/mido/reminders`,
        method: "PUT",
        timeout: 5000,
        data: reminder,
        headers: {
            "content-type": "application/json",
            Authorization: `bearer ${accessToken}`,
        },
    };

    const { data, error, statusCode } = (await callApi({ config })) as ApiResponse<Reminder>;
    console.log(error);
    var _error: AppError | null = error;
    const valEr = error as ValidationError;
    if(valEr) {
        _error = {message: "Validation Error occured"} as AppError;
    }
    return {
        data,
        error: _error,
        statusCode,
    };

}


export const deleteReminder = async (accessToken: string, teamId: string, reminderId: string): Promise<ApiResponse<MessageResponse>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/teams/${teamId}/mido/reminder/${reminderId}`,
        method: "DELETE",
        timeout: 5000,
        headers: {
            "content-type": "application/json",
            Authorization: `bearer ${accessToken}`,
        },
    };

    const { data, error, statusCode } = (await callApi({ config })) as ApiResponse<MessageResponse>;
    console.log(error);
    var _error: AppError | null = error;
    const valEr = error as ValidationError;
    if(valEr) {
        _error = {message: "Validation Error occured"} as AppError;
    }
    return {
        data,
        error: _error,
        statusCode,
    };

}


export const putRuleset = async (accessToken: string, teamId: string, ruleset: RuleSet): Promise<ApiResponse<RuleSet>> => {
    ruleset.team = teamId;
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/teams/${teamId}/mido/rulesets`,
        method: "PUT",
        timeout: 5000,
        data: ruleset,
        headers: {
            "content-type": "application/json",
            Authorization: `bearer ${accessToken}`,
        },
    };

    const { data, error, statusCode } = (await callApi({ config })) as ApiResponse<RuleSet>;
    console.log(error);
    var _error: AppError | null = error;
    const valEr = error as ValidationError;
    if(valEr) {
        _error = {message: "Validation Error occured"} as AppError;
    }
    return {
        data,
        error: _error,
        statusCode
    };

}

export const deleteRuleSet = async (accessToken: string, teamId: string, rulesetId: string): Promise<ApiResponse<MessageResponse>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/teams/${teamId}/mido/ruleset/${rulesetId}`,
        method: "DELETE",
        timeout: 5000,
        headers: {
            "content-type": "application/json",
            Authorization: `bearer ${accessToken}`,
        },
    };

    const { data, error, statusCode } = (await callApi({ config })) as ApiResponse<MessageResponse>;
    console.log(error);
    var _error: AppError | null = error;
    const valEr = error as ValidationError;
    if(valEr) {
        _error = {message: "Validation Error occured"} as AppError;
    }
    return {
        data,
        error: _error,
        statusCode,
    };

}
