import { AxiosRequestConfig } from "axios";
import { callApi } from "./call-api";
import { ApiResponse } from "../models/base";
import { Issue, IssueComment } from "../models/issues";
const apiServerUrl = process.env.REACT_APP_MIDO_API_URL;


export const listTeamIssues = async (accessToken: string, teamId: string): Promise<ApiResponse<Issue[]>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/teams/${teamId}/mido/issues`,
        method: "GET",
        timeout: 5000,
        headers: {
            "content-type": "application/json",
            Authorization: `bearer ${accessToken}`,
        },
    };
    return (await callApi({ config })) as ApiResponse<Issue[]>;
};



export const getTeamIssue = async (accessToken: string, teamId: string, issueId: string): Promise<ApiResponse<Issue>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/teams/${teamId}/mido/issues/{issueId}`,
        method: "GET",
        timeout: 5000,
        headers: {
            "content-type": "application/json",
            Authorization: `bearer ${accessToken}`,
        },
    };
    return (await callApi({ config })) as ApiResponse<Issue>;
};


export const addCommentToIssue = async (accessToken: string, teamId: string, issueId: string, text: string): Promise<ApiResponse<IssueComment>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/teams/${teamId}/mido/issues/${issueId}`,
        method: "POST",
        timeout: 5000,
        data: {
            text: text,
        },
        headers: {
            "content-type": "application/json",
            Authorization: `bearer ${accessToken}`,
        },
    };
    return (await callApi({ config })) as ApiResponse<IssueComment>;
};

