import React, { Reducer } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { CurrentUserContext } from "./UserProvider";
import { Contact, DateProperty, NumericProperty, Reminder, RuleSet, StringProperty } from "../models/reminders";
import { listTeamContacts, listTeamReminders, listTeamRules, putContact, putReminder, putRuleset } from "../services/reminders";
import { ApiResponse } from "../models/base";
import parseJSON from "date-fns/parseJSON";


export interface TeamState {
    reminders: Reminder[] | null;
    contacts: Contact[] | null;
    ruleSets: RuleSet[] | null;
    error: string;
    teamId: string;
}


export interface TeamRemindersContextType {
    data: TeamState;
    loadContacts: (reload: boolean) => Promise<void>;
    loadRuleSets: (reload: boolean) => Promise<void>;
    loadReminders: (reload: boolean) => Promise<void>;
    saveContact: (contact: Contact) => Promise<ApiResponse<Contact>>;
    saveReminder: (reminder: Reminder) => Promise<ApiResponse<Reminder>>;
    saveRuleSet: (ruleSet: RuleSet) => Promise<ApiResponse<RuleSet>>;
};

enum ActionType {
    CONTACTS = 'set-contacts',
    REMINDERS = 'set-reminders',
    RULESETS = 'set-ruleSets',
    TEAMID = 'set-teamId',
    ERROR = 'error',
}


type Action =
    | { type: ActionType.CONTACTS, contacts: Contact[] }
    | { type: ActionType.REMINDERS, reminders: Reminder[] }
    | { type: ActionType.RULESETS, ruleSets: RuleSet[] }
    | { type: ActionType.TEAMID, teamId: string }
    | { type: ActionType.ERROR, error: string };


// type Reducer<S, A> = (state: S, action: A) => S;

const teamStateReducer = (state: TeamState, action: Action): TeamState => {
    switch (action.type) {
        case ActionType.CONTACTS:
            return { ...state, contacts: action.contacts };
        case ActionType.REMINDERS:
            return { ...state, reminders: action.reminders };
        case ActionType.RULESETS:
            return { ...state, ruleSets: action.ruleSets };
        case ActionType.TEAMID:
            return { ...state, teamId: action.teamId };
        case ActionType.ERROR:
            return { ...state, error: action.error };
    }
    return state;
}

export const TeamRemindersContext = React.createContext<TeamRemindersContextType | null>(null);

type props = {
    children: React.ReactNode;
};

export const TeamRemindersProvider = function ({ children }: props) {
    const { user, getAccessTokenSilently } = useAuth0();
    const userData = React.useContext(CurrentUserContext);

    const [state, dispatch] = React.useReducer<Reducer<TeamState, Action>>(teamStateReducer, { error: "", teamId: "" } as TeamState);

    const loadContacts = React.useCallback(async (reload: boolean = false): Promise<void> => {
        const token = await getAccessTokenSilently();
        const rem = await listTeamContacts(token, userData!.currentTeam!.team._id);
        if (rem.data) {
            dispatch({ type: ActionType.CONTACTS, contacts: rem.data });
        } else {
            dispatch({ type: ActionType.ERROR, error: rem.error!.message });
        }
    }, [userData, getAccessTokenSilently]);

    const fixDateProperties = (reminder: Reminder): Reminder => {
        if (reminder.properties) {
            const properties = reminder.properties.map((property: StringProperty | NumericProperty | DateProperty): StringProperty | NumericProperty | DateProperty => {
                if (typeof property.value === "string") {
                    try {
                        const value = parseJSON(property.value);
                        return { ...property, value };
                    } catch (err) {
                        console.log(err);
                    }
                }
                return property;
            });
            return { ...reminder, properties }
        }
        return reminder;
    }

    const loadReminders = React.useCallback(async (reload: boolean = false): Promise<void> => {
        const token = await getAccessTokenSilently();
        const rem = await listTeamReminders(token, userData!.currentTeam!.team._id);
        if (rem.data) {
            const reminders = rem.data.map(fixDateProperties);
            dispatch({ type: ActionType.REMINDERS, reminders: reminders });
        } else {
            dispatch({ type: ActionType.ERROR, error: rem.error!.message });
        }
    }, [userData, getAccessTokenSilently]);

    const saveContact = async (contact: Contact): Promise<ApiResponse<Contact>> => {
        const token = await getAccessTokenSilently();
        const response = await putContact(token, userData!.currentTeam!.team._id, contact);
        if (response.data) {
            if (state.contacts === null) {
                dispatch({ type: ActionType.CONTACTS, contacts: [response.data] });
            }
            const index = state.contacts!.findIndex(cc => cc._id === response.data!._id);
            const _contacts = (index >= 0) ?
                [...state.contacts!.slice(0, index), response.data!, ...state.contacts!.slice(index + 1)]
                :
                [...state.contacts!, response.data!];
            dispatch({ type: ActionType.CONTACTS, contacts: _contacts });
        }
        return response;
    }

    const saveRuleSet = async (ruleSet: RuleSet): Promise<ApiResponse<RuleSet>> => {
        const token = await getAccessTokenSilently();
        const response = await putRuleset(token, userData!.currentTeam!.team._id, ruleSet);
        if (response.data) {
            if (state.ruleSets === null) {
                dispatch({ type: ActionType.RULESETS, ruleSets: [response.data] });
            }
            const index = state.ruleSets!.findIndex(cc => cc._id === response.data!._id);
            const _rules = (index >= 0) ?
                [...state.ruleSets!.slice(0, index), response.data!, ...state.ruleSets!.slice(index + 1)]
                :
                [...state.ruleSets!, response.data!];
            dispatch({ type: ActionType.RULESETS, ruleSets: _rules });
        }
        return response;
    }

    const saveReminder = async (reminder: Reminder): Promise<ApiResponse<Reminder>> => {
        const token = await getAccessTokenSilently();
        const response = await putReminder(token, userData!.currentTeam!.team._id, reminder);
        if (response.data) {
            if (state.reminders === null) {
                dispatch({ type: ActionType.REMINDERS, reminders: [response.data!] });
            }
            const index = state.reminders!.findIndex(cc => cc._id === response.data!._id);
            const _reminders = (index >= 0) ?
                [...state.reminders!.slice(0, index), fixDateProperties(response.data!), ...state.reminders!.slice(index + 1)]
                :
                [...state.reminders!, response.data!];
            dispatch({ type: ActionType.REMINDERS, reminders: _reminders });
        }
        return response;
    }

    const loadRuleSets = React.useCallback(async (reload: boolean = false): Promise<void> => {
        console.log("loadRuleSets called");
        const token = await getAccessTokenSilently();
        const response = await listTeamRules(token, userData!.currentTeam!.team._id);
        console.log(response);
        if (response.data || response.data!.length === 0) {
            dispatch({ type: ActionType.RULESETS, ruleSets: response.data! });
        } else {
            dispatch({ type: ActionType.ERROR, error: response.error!.message });
        }
    }, [userData, getAccessTokenSilently]);

    const loadAll = React.useCallback(async () => {
        console.log("loadAll called");
        if (user && userData && userData.currentTeam) {
            if (state.reminders === null || state.teamId !== userData!.currentTeam!.team._id) {
                loadReminders().then(res => {
                })
                    .catch(error => {
                        console.log(error)
                        dispatch({ type: ActionType.ERROR, error: error.message });
                    });
            }
            if (state.contacts === null || state.teamId !== userData!.currentTeam!.team._id) {
                loadContacts().then(res => {
                })
                    .catch(error => {
                        console.log(error)
                        dispatch({ type: ActionType.ERROR, error: error.message });
                    });
            }
            if (state.ruleSets === null || state.teamId !== userData!.currentTeam!.team._id) {
                loadRuleSets().then(res => {
                })
                    .catch(error => {
                        console.log(error)
                        dispatch({ type: ActionType.ERROR, error: error.message });
                    });
            }
            if (state.teamId !== userData!.currentTeam!.team._id) {
                dispatch({ type: ActionType.TEAMID, teamId: userData!.currentTeam!.team._id });
            }
        }
    }, [user, userData, loadReminders, loadContacts, loadRuleSets, state]);

    loadAll();

    return (
        <TeamRemindersContext.Provider value={{
            data: state,
            loadContacts,
            loadReminders,
            loadRuleSets,
            saveContact,
            saveReminder,
            saveRuleSet,
        }}>
            {children}
        </TeamRemindersContext.Provider>
    );
}

