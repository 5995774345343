import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { colors } from '../utils';



interface FooterItem {
    title: string;
    description: string[][];
}

const footers:FooterItem[] = [
    // {
    //     title: 'Company',
    //     description: [['About Us', '/company/about'], ['Contact us', '/company/contact'], ['Plans', '/pricing']],
    // },
    // {
    //     title: 'Resources',
    //     description: [['Support', '/support'], ['User Guide', '/guide']],
    // },
    // {
    //     title: 'Legal',
    //     description: [['Privacy policy', '/privacy'], ['Terms of use', '/terms']],
    // },
];


function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" to="https://mido-app.com/">
                Mido
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

interface props {
    open: boolean;
}

export default function Footer({open}: props) {
    return (
        <Container
            maxWidth={false}
            sx={{
                borderTop: 4,
                margin: 0,
                width: "100%",
                backgroundColor: colors.PigeonPost,
            }}
        >
            <Container
                maxWidth="md"
                component="footer"
                sx={{
                    mt: 8,
                    py: [3, 6],
                    marginLeft: open ?  30 : 0,
                }}
            >
                <Grid container spacing={4} justifyContent="space-evenly">
                    {footers.map((footer) => (
                        <Grid item xs={6} sm={3} key={footer.title}>
                            <Typography variant="h6" color="text.primary" gutterBottom>
                                {footer.title}
                            </Typography>
                            <ul>
                                {footer.description.map((item) => (
                                    <li key={item[1]}>
                                        <Link to={item[1]} color="text.secondary">
                                            {item[0]}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Grid>
                    ))}
                </Grid>
                <Copyright sx={{ mt: 5 }} />
            </Container>
            {/* End footer */}
        </Container>
    );
}