import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
    GridRenderCellParams,
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridRowParams,
    GridCallbackDetails,
} from '@mui/x-data-grid';
import { MuiEvent } from '@mui/x-data-grid';
import { TeamRemindersContext } from '../contexts/RemindersProvider';
import { Contact } from '../models/reminders';
import { Alert, AlertTitle, Button, ButtonGroup, Collapse, IconButton, Tooltip } from '@mui/material';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import ControlPointTwoToneIcon from '@mui/icons-material/ControlPointTwoTone';
import ContactDetails from './contact-detail';
import CloseIcon from '@mui/icons-material/Close';
import { deleteContact } from '../services/reminders';
import { useAuth0 } from '@auth0/auth0-react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';



export default function Contacts() {
    const { getAccessTokenSilently } = useAuth0();
    const teamContext = React.useContext(TeamRemindersContext);
    const [contacts, setContacts] = React.useState<Contact[]>([]);
    const [open, setOpen] = React.useState<boolean>(false);
    const [showError, setShowError] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>("");
    const [contact, setContact] = React.useState<Contact>({ name: "", email: "" });
    const theme = useTheme();
    const big = useMediaQuery(theme.breakpoints.up('md'));

    const handleDelete = async (contactId: string) => {
        const token = await getAccessTokenSilently();
        const response = await deleteContact(token, teamContext!.data.teamId, contactId);
        teamContext!.loadContacts(true);
        if (response.error && response.statusCode >= 300) {
            setShowError(true);
            setError(response.error.message);
        }
    }

    function renderRowActions(
        params: GridRenderCellParams<{ id: string, name: string }, any, any>,
    ) {
        return (
            <ButtonGroup variant="contained" aria-label="team actions" color='primary'>
                <Tooltip title={"See " + params.row.name + " details"}>
                    <Button
                        size="small"
                        aria-label={"See " + params.row.name + " details"}
                        endIcon={<InfoTwoToneIcon />}
                        onClick={() => showDetailDialog(params.row.id)}
                    >
                        {big && "Details"}
                    </Button>
                </Tooltip>
                <Tooltip title={"delete " + params.row.name}>
                    <Button
                        size="small"
                        aria-label={"delete " + params.row.name}
                        endIcon={<HighlightOffTwoToneIcon />}
                        onClick={() => handleDelete(params.row.id)}
                    >
                        {big && "Remove"}
                    </Button>
                </Tooltip>
            </ButtonGroup>
        );
    }

    const showDetailDialog = (contactId: string | null) => {
        if (contactId === null) {
            setContact({ name: "", email: "" });
            setOpen(true);
        } else {
            const contact = contacts!.find(contact => contact._id === contactId);
            if (contact) {
                setContact(contact);
                setOpen(true);
            } else {
                setError("Contact not found");
            }

        }
    }

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        {
            field: 'id',
            minWidth: big ? 220: 70,
            headerName: "actions",
            renderCell: renderRowActions,
            hideable: false,
            valueGetter: (params) => params.row.name == null ? null : { id: params.row.id, name: params.row.name },
        },
    ];


    function EditToolbar() {
        const handleClick = () => {
            showDetailDialog(null);
        };

        return (
            <GridToolbarContainer sx={{ marginBottom: 1 }}>
                <Tooltip title="Create Contact">
                    <Button color="primary" variant='contained' startIcon={<ControlPointTwoToneIcon />} onClick={handleClick}>
                        Create Contact
                    </Button>
                </Tooltip>
            </GridToolbarContainer>
        );
    }

    React.useMemo(() => {
        if (teamContext && teamContext.data.contacts) {
            const cleanContacts = teamContext.data.contacts.map((contact) => {
                return {
                    ...contact,
                    id: contact._id,
                }
            });
            setContacts(cleanContacts);
        }
    }, [teamContext]);

    const rowEditRequested = (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        showDetailDialog(params.row._id);
    };

    return (
        <React.Fragment>
            <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Contacts
                </Typography>

                <Collapse in={showError}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowError(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>
                </Collapse>
                <DataGrid
                    rows={contacts}
                    columns={columns}
                    autoHeight
                    onRowDoubleClick={rowEditRequested}
                    pageSizeOptions={[5, 10, 25, 50]}
                    disableRowSelectionOnClick
                    disableColumnMenu={true}
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                        },
                    }}
                />


                <ContactDetails open={open}
                    setOpen={setOpen}
                    contact={contact}
                    setContact={setContact} />
            </Container>
        </React.Fragment>
    );
}
