import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Hero from '../assets/elephants-dust.jpg';
// import { NaturalCompletion } from '../components/completions/natural-completion';

export default function HomePage() {

    return (
        <React.Fragment>
            <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Forget About Forgetting
                </Typography>
                {/* <NaturalCompletion /> */}
                &nbsp;
                <img src={Hero} width="100%" alt="Elephants walking. Black and white with a hazy sky and back lighting." />
                <Typography variant="h5" align="center" color="text.secondary" component="p">
                    Comming Soon!
                </Typography>
            </Container>
        </React.Fragment>
    );
}
