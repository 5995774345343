import React from "react";
import { CurrentUserContext } from "../contexts/UserProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { SubmitButton } from "../components/submit-button";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Alert, AlertTitle, Collapse,
    FormControl,
    FormHelperText,
    IconButton, OutlinedInput, Paper, Stack, Typography, useMediaQuery, useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Issue } from '../models/issues';
import { addCommentToIssue } from "../services/issues";
import moment from 'moment';


type IssueDetailsProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    issue: Issue | null;
    setIssue: React.Dispatch<React.SetStateAction<Issue | null>>;
};


export default function IssueDetails({ open, setOpen, issue, setIssue }: IssueDetailsProps) {
    const userContext = React.useContext(CurrentUserContext);
    const {getAccessTokenSilently} = useAuth0();
    const [loading, setLoading] = React.useState(false);
    const [submitDisabled, setSubmitDisabled] = React.useState(true);
    const [showError, setShowError] = React.useState(false);
    const [newComment, setNewComment] = React.useState("");
    const [error, setError] = React.useState("");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const handleFormSubmit = React.useCallback(async () => {
        if(!userContext || !userContext.currentTeam || !issue) {
            setShowError(true);
            setError("Invalid User State");
        }
        setLoading(true);
        const token = await getAccessTokenSilently();
        const response = await addCommentToIssue(token, userContext!.currentTeam!.team._id, issue!.id, newComment);
        if (response.data) {
            setLoading(false);
            setSubmitDisabled(true);
            setOpen(false);
            var newIssue = {...issue};
            if(!newIssue.comments) newIssue.comments = [];
            newIssue.comments.push(response.data);
            setIssue(newIssue as Issue);
            setNewComment("");
        } else {
            setError(response.error!.message as string);
            setShowError(true);
            setLoading(false);
        }
    }, [newComment, setIssue, userContext, getAccessTokenSilently, setOpen, issue]);

    const newCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewComment(event.target.value);
        setSubmitDisabled(false);
    }

    const handleClose = () => {
        setOpen(false);
        setLoading(false);
    };

    return (
        <Dialog open={open} fullWidth onClose={handleClose} scroll="paper" fullScreen={fullScreen}>
            <DialogTitle>{issue && issue.summary}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: 2 }}>
                    {issue && issue.description}
                </DialogContentText>
                {/* =================================  GENERAL  ================================= */}
                <Paper elevation={1} sx={{ padding: 3, marginBottom: 1 }}>
                    <Stack spacing={2}>
                        <Typography>{issue && moment(issue.created).fromNow()}</Typography>
                    </Stack>
                </Paper>

                <Paper elevation={1} sx={{ padding: 3, marginBottom: 1 }}>
                    <Stack spacing={2}>
                        {issue && issue.comments.map((comment) => {
                            return (
                                <Stack spacing={2}>
                                    <Typography>{comment.content}</Typography>
                                    <Stack spacing={2} direction="row">
                                        <Typography sx={{ flex: 1 }}>{
                                            userContext && userContext.userData && userContext.userData.user &&
                                        comment.mido_user === userContext.userData.user._id ? "Me" : "MIDO Support" }</Typography>
                                        <Typography sx={{ flex: 1 }}>{moment(comment.created).fromNow()}</Typography>
                                    </Stack>

                                </Stack>
                            );
                        })}
                        <Stack spacing={2}>
                            <FormControl fullWidth={true}>
                                <OutlinedInput
                                    fullWidth={true}
                                    id="comment-text"
                                    value={newComment}
                                    onChange={newCommentChange}
                                    aria-describedby="comment-text-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="comment-text-helper-text">Comment</FormHelperText>
                            </FormControl>
                        </Stack>
                    </Stack>
                </Paper>

                <Collapse in={showError}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowError(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>
                </Collapse>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <SubmitButton
                    label="Add Comment"
                    clickHandler={handleFormSubmit}
                    loading={loading}
                    setLoading={setLoading}
                    disabled={submitDisabled}
                />
            </DialogActions>
        </Dialog>
    );
}


