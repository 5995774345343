

export default function Calculating() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="calculating" version="1.1" x="0" y="0" width="50" height="15"
            viewBox="-4, -4, 104, 34">
            <g id="row1">
                <circle className="cc3" cy="5" cx="5" r="4" />
                <circle className="cc2" cy="5" cx="15" r="4" />
                <circle className="cc1" cy="5" cx="25" r="4" />
                <circle className="cc3" cy="5" cx="45" r="4" />
                <circle className="cc1" cy="5" cx="65" r="4" />
                <circle className="cc2" cy="5" cx="75" r="4" />
            </g>
            <g id="row2" transform="translate(0,10)">
                <circle className="cc3" cy="5" cx="15" r="4" />
                <circle className="cc1" cy="5" cx="35" r="4" />
                <circle className="cc2" cy="5" cx="45" r="4" />
                <circle className="cc2" cy="5" cx="75" r="4" />
                <circle className="cc1" cy="5" cx="85" r="4" />
            </g>
            <g id="row3" transform="translate(0,20)">
                <circle className="cc3" cy="5" cx="15" r="4" />
                <circle className="cc2" cy="5" cx="25" r="4" />
                <circle className="cc1" cy="5" cx="55" r="4" />
                <circle className="cc2" cy="5" cx="95" r="4" />
            </g>
        </svg>
    );
}




