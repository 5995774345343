import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { CurrentUserContext } from '../contexts/UserProvider';
import { User } from '../models/teams';
import { FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Checkbox } from '@mui/material';
import { Alert, AlertTitle, Button, Collapse, IconButton, Skeleton, Stack, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { SubmitButton } from "../components/submit-button";
import { Item } from "../components/grid-item";


export default function ProfilePage() {
    const userContext = React.useContext(CurrentUserContext);
    const [user, setUser] = React.useState<User | null>(null);
    const [error, setError] = React.useState<string | null>(null);
    const [submitDisabled, setSubmitDisabled] = React.useState(true);
    const [loading, setLoading] = React.useState(false);


    const getUser = React.useCallback(() => {
        if (userContext && userContext.userData && userContext.userData.user) {
            setUser(userContext.userData.user);
        }
    }, [setUser, userContext]);

    React.useMemo(() => {
        getUser();
    }, [getUser]);


    const resetUser = () => {
        getUser();
        setSubmitDisabled(true);
    }

    const updateMarketingEmails = (marketing_emails: boolean) => {
        if (user) {
            setUser({
                ...user,
                marketing_emails,
            });
            setSubmitDisabled(false);
        }
    }

    const setNewsEmails = (news_emails: boolean) => {
        if (user) {
            setUser({
                ...user,
                news_emails,
            });
            setSubmitDisabled(false);
        }
    }


    const handleFormSubmit = async () => {
        if (userContext && user) {
            setLoading(true);
            await userContext.saveUser(user!);
            setSubmitDisabled(true);
            setLoading(false);
        } else {
            setError("User session invalidated. Please reload the page.")
        }
    }

    const setName = (name: string) => {
        if (user) {
            setUser({
                ...user,
                name,
            });
            setSubmitDisabled(false);
        }
    }

    const setEmail = (email: string) => {
        if (user) {
            setUser({
                ...user,
                email,
            });
            setSubmitDisabled(false);
        }
    }

    const setFullName = (full_name: string) => {
        if (user) {
            setUser({
                ...user,
                full_name,
            });
            setSubmitDisabled(false);
        }
    }


    return (
        <React.Fragment>
            <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    My Profile
                </Typography>

                <Collapse in={error !== null}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setError(null);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>
                </Collapse>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Item>
                                <Typography variant="h4" textAlign="left">User Info</Typography>
                                {user ? (
                                    <Collapse in={user !== null}>
                                        <Stack spacing={2} marginY={2}>
                                            <TextField
                                                fullWidth={true}
                                                size="small"
                                                id="name"
                                                label="Name"
                                                value={user ? user.name : ""}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setName(event.target.value);
                                                }}
                                            />

                                            <TextField
                                                fullWidth={true}
                                                size="small"
                                                id="name"
                                                label="email"
                                                value={user ? user.email : ""}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setEmail(event.target.value);
                                                }}
                                            />

                                            <TextField
                                                fullWidth={true}
                                                size="small"
                                                id="full_name"
                                                label="Full Name"
                                                value={user && user.full_name ? user.full_name : ""}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setFullName(event.target.value);
                                                }}
                                            />

                                        </Stack>
                                    </Collapse>
                                ) : (
                                    <Collapse in={user === null}>
                                        <Stack spacing={2} marginY={2}>
                                            <Skeleton variant="rounded" width="100%" height="40dp" />
                                            <Skeleton variant="rounded" width="100%" height="40dp" />
                                            <Skeleton variant="rounded" width="100%" height="40dp" />
                                        </Stack>
                                    </Collapse>

                                )}
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Item>
                                <Typography variant="h4" textAlign="left">Email Preferences</Typography>
                                {user ? (
                                    <Collapse in={user !== null}>
                                        <Stack spacing={2} marginY={2}>
                                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox checked={user.marketing_emails === true} onChange={(event) => {
                                                                updateMarketingEmails(event.target.checked);
                                                            }} name="marketing_emails" />
                                                        }
                                                        label="Marketing Emails"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox checked={user.news_emails === true} onChange={(event) => {
                                                                setNewsEmails(event.target.checked);
                                                            }} name="news_emails" />
                                                        }
                                                        label="News Emails"
                                                    />
                                                </FormGroup>
                                                <FormHelperText>May take up to 7 days before email Preferences take effect</FormHelperText>
                                            </FormControl>

                                        </Stack>
                                    </Collapse>
                                ) : (
                                    <Collapse in={user === null}>
                                        <Stack spacing={2} marginY={2}>
                                            <Skeleton variant="rounded" width="100%" height="40dp" />
                                            <Skeleton variant="rounded" width="100%" height="40dp" />
                                            <Skeleton variant="rounded" width="100%" height="40dp" />
                                        </Stack>
                                    </Collapse>

                                )}
                            </Item>
                        </Grid>
                        <Grid item xs={12}>
                            <Item>
                                <Stack direction="row">
                                    <SubmitButton
                                        label="Save"
                                        clickHandler={handleFormSubmit}
                                        loading={loading}
                                        setLoading={setLoading}
                                        disabled={submitDisabled}
                                    />
                                    <Box sx={{ m: 1, position: 'relative' }}>
                                        <Button
                                            variant="outlined"
                                            onClick={resetUser}
                                            disabled={submitDisabled}>
                                            Reset
                                        </Button>
                                    </Box>
                                </Stack>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>


            </Container>
        </React.Fragment>
    );
}
