import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { styled } from '@mui/material/styles';
import { CurrentUserContext } from "../contexts/UserProvider";
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import { SubmitButton } from "../components/submit-button";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTeam } from "../services/teams";
import Typography from '@mui/material/Typography';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
import { ButtonGroup, IconButton, Tooltip } from "@mui/material";
import PersonRemoveTwoToneIcon from '@mui/icons-material/PersonRemoveTwoTone';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import { Link } from "react-router-dom";
import { MIDO_ID } from "../models/teams";


import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import SupervisedUserCircleTwoToneIcon from '@mui/icons-material/SupervisedUserCircleTwoTone';
import OfflineBoltTwoToneIcon from '@mui/icons-material/OfflineBoltTwoTone';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



const TeamHome = () => {
    const userContext = React.useContext(CurrentUserContext);

    return (
        <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
            <Typography
                component="h1"
                variant="h2"
                align="center"
                color="text.primary"
                gutterBottom
            >
                My Teams
            </Typography>
            {(userContext!.userData && !userContext!.userData!.teams) ? (
                <>
                    <Alert severity="info">You do not belong to any teams</Alert>
                    <Typography variant="h6" color="inherit" align='left'>
                        Have the team owner invite you to the team or create a team.
                    </Typography>
                    <CreateTeamDialog label="Create A Team of Your Own" />
                </>
            ) : (
                <>
                    <TableContainer component={Paper}>
                        <Table sx={{ width: "auto" }} aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>{""}</StyledTableCell>
                                    <StyledTableCell>Team Name</StyledTableCell>
                                    <StyledTableCell>Plan</StyledTableCell>
                                    <StyledTableCell>Actions</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {userContext!.userData!.teams!.map((team) => (
                                    <StyledTableRow
                                        key={team.team._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <StyledTableCell component="td" scope="row">
                                            {team.type === "member" ? (
                                                <Tooltip title="Member">
                                                    <AccountCircleTwoToneIcon color="primary" aria-label="Member" />
                                                </Tooltip>) :
                                                (team.type === "admin" ? (
                                                    <Tooltip title="Admin">
                                                        <SupervisedUserCircleTwoToneIcon color="primary" aria-label="Admin" />
                                                    </Tooltip>) :
                                                    (
                                                        <Tooltip title="Owner">
                                                            <OfflineBoltTwoToneIcon color="primary" aria-label="Owner" />
                                                        </Tooltip>)
                                                )
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ width: "100%" }} component="td" scope="row">
                                            <Link to={"/teams/" + team.team._id}><Typography variant="body1">{team.team.name}</Typography></Link>
                                        </StyledTableCell>
                                        <StyledTableCell component="td" scope="row">
                                            {team.team.plan}
                                        </StyledTableCell>
                                        <StyledTableCell component="td" scope="row">
                                            <ButtonGroup variant="text" aria-label="team actions">
                                                <Tooltip title={"leave " + team.team.name + " team"}>
                                                    <IconButton color="primary" aria-label={"leave " + team.team.name + " team"} component="label">
                                                        <PersonRemoveTwoToneIcon color="primary" />
                                                    </IconButton>
                                                </Tooltip>

                                                {(team.type === "superuser" || team.type === "admin") ? (
                                                    <Tooltip title={team.team.name + "settings"}>
                                                        <Link to={"/teams/" + team.team._id}>
                                                            <IconButton color="primary" aria-label={team.team.name + " settings"} component="label">
                                                                <SettingsTwoToneIcon color="primary" />
                                                            </IconButton>
                                                        </Link>
                                                    </Tooltip>
                                                ) : (<></>)}

                                                {(team.type === "superuser" && team.team._id !== MIDO_ID) ? (
                                                    <Tooltip title={"delete " + team.team.name}>
                                                        <IconButton color="primary" aria-label={"delete " + team.team.name} component="label">
                                                            <HighlightOffTwoToneIcon color="warning" />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : (<></>)}

                                            </ButtonGroup>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <CreateTeamDialog label="Create New Team" />
                </>
            )
            }
        </Container>
    );

}

export default TeamHome;



type createTeamDialogProps = {
    label: string;
};


export function CreateTeamDialog({ label }: createTeamDialogProps) {
    const userContext = React.useContext(CurrentUserContext);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [subtext, setSubtext] = React.useState("Enter a team name");
    const [value, setValue] = React.useState("");
    const [teamName, setTeamName] = React.useState("");
    const { getAccessTokenSilently } = useAuth0();
    const [submitDisabled, setSubmitDisabled] = React.useState(true);

    const openCreateTeamDialog = () => {
        setOpen(true);
    };

    const handleFormSubmit = async () => {
        const token = await getAccessTokenSilently()
        const response = await createTeam(token, teamName);
        if (response.data) {
            handleClose();
            userContext!.loadUser(true);
            setError(false);
        }
        setError(true);
        setSubtext(
            response.error ? response.error!.message : "This team name is unavailable"
        );
        setLoading(false);
    };

    const handleClose = () => {
        setOpen(false);
        setValue("");
        setLoading(false);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        if (newValue === "add_team") {
            openCreateTeamDialog();
        }
    };

    return (
        <div>
            <BottomNavigation sx={{ width: "auto", alignSelf: "center", boxShadow: 2, marginTop: 2 }}
                showLabels value={value} onChange={handleChange}>
                <BottomNavigationAction
                    label={label}
                    title={label}
                    value="add_team"
                    icon={<GroupAddTwoToneIcon />}
                />
            </BottomNavigation>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create a team</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        A Team allows you to invite others to participate in your MIDO reminders. It is also the owner of the subscription plans.
                    </DialogContentText>
                    <TextField
                        error={error}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Team Name"
                        value={teamName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setSubmitDisabled(false);
                            setTeamName(event.target.value);
                        }}
                        type="text"
                        fullWidth
                        variant="standard"
                        helperText={subtext}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <SubmitButton disabled={submitDisabled} label="Create" clickHandler={handleFormSubmit} loading={loading} setLoading={setLoading} />
                </DialogActions>
            </Dialog>
        </div>
    );
}


