

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export function enumValues<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.values(obj).filter(k => Number.isNaN(+k)) as K[];
}

export const colors = {
    // "Davy's gray":"#494953",
    // "Gray":"#848487",
    "Jet": "#3a3335",
    // "French gray":"#cacad4",
    // "Dim gray":"#68686c",
    "GhostWhite":"#f8faff",
    // "blue": "#67adec",
    "red": "#ff2d2d",
    // new colors
    "Mirage": "#1E2837",
    "Nepal": "#86A2C3",
    "PigeonPost": "#A9C5DC",
    "Sisal": "#D6CBC1",
}

// https://colorpalettes.net/color-palette-4210/
// #765b58
// #b9a195
// #dad2c5
// #e6d6c5
// #d5a069



export class cloneable {
    public static deepCopy<T>(source: T): T {
        return Array.isArray(source)
            ? source.map(item => this.deepCopy(item))
            : source instanceof Date
                ? new Date(source.getTime())
                : source && typeof source === 'object'
                    ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
                        Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
                        o[prop] = this.deepCopy((source as { [key: string]: any })[prop]);
                        return o;
                    }, Object.create(Object.getPrototypeOf(source)))
                    : source as T;
    }
}