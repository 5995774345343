import React from "react";
// import { useAuth0 } from "@auth0/auth0-react";
// import { CurrentUserContext } from "../contexts/UserProvider";
import { TeamRemindersContext } from '../contexts/RemindersProvider';
import { SubmitButton } from "../components/submit-button";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DateProperty, Link, NumericProperty, Reminder, StringProperty } from "../models/reminders";
import { ButtonGroup, Grid, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import LibraryAddTwoToneIcon from '@mui/icons-material/LibraryAddTwoTone';
import { RuleDropdownItem, RulesComboBox } from "../components/rules-combobox";
import { ChangeResponse } from '../models/base';
import { Alert, AlertTitle, Collapse } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Item } from "../components/grid-item";
import PropertyEditor from "../components/property-editor";


type reminderDetailProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    reminder: Reminder;
    setReminder: React.Dispatch<React.SetStateAction<Reminder>>;
};


export default function ReminderDetails({ open, setOpen, reminder, setReminder }: reminderDetailProps) {
    const [loading, setLoading] = React.useState(false);
    const [submitDisabled, setSubmitDisabled] = React.useState(true);
    const teamContext = React.useContext(TeamRemindersContext);
    const [rules, setRules] = React.useState<RuleDropdownItem[]>([]);
    const [showError, setShowError] = React.useState(false);
    const [error, setError] = React.useState("");

    const setProperty = (index: number, property: StringProperty | DateProperty | NumericProperty) => {
        setReminder(
            {
                ...reminder,
                properties: [...reminder.properties!.slice(0, index), property, ...reminder.properties!.slice(index + 1)]
            }
        )
        setSubmitDisabled(false);
    };

    const setName = (name: string) => {
        setReminder(
            {
                ...reminder,
                name: name,
            }
        )
        setSubmitDisabled(false);
    }
    const setDescription = (description: string) => {
        setReminder(
            {
                ...reminder,
                description: description,
            }
        )
        setSubmitDisabled(false);
    }

    const handleFormSubmit = async () => {
        setLoading(true);
        const response = await teamContext!.saveReminder(reminder);
        if (response.data) {
            setLoading(false);
            setSubmitDisabled(true);
            setOpen(false);
        } else {
            setError(response.error!.message as string);
            setShowError(true);
            setLoading(false);
        }
    };




    const handleClose = () => {
        setOpen(false);
        setLoading(false);
    };

    const removeRule = (index: number) => {
        if (reminder && reminder.rulesets) {
            const _rules = [...reminder.rulesets.slice(0, index), ...reminder.rulesets.slice(index + 1)];
            setReminder(
                {
                    ...reminder,
                    rulesets: _rules,
                }
            );
            setSubmitDisabled(false);
        }
    }

    const removeProperty = (index: number) => {
        if (reminder && reminder.properties) {
            const properties = [...reminder.properties.slice(0, index), ...reminder.properties.slice(index + 1)];
            setReminder(
                {
                    ...reminder,
                    properties,
                }
            );
            setSubmitDisabled(false);
        }
    }

    const removeLink = (index: number) => {
        if (reminder && reminder.links) {
            const links = [...reminder.links.slice(0, index), ...reminder.links.slice(index + 1)];
            setReminder(
                {
                    ...reminder,
                    links,
                }
            );
            setSubmitDisabled(false);
        }
    }

    const addLink = () => {
        const newLink = { name: "", url: "" } as Link;
        const links: Link[] = reminder.links ? [...reminder.links, newLink] : [newLink];
        setReminder(
            {
                ...reminder,
                links,
            }
        );
        setSubmitDisabled(false);
    }

    const setLinkName = (index: number, value: string) => {
        if (reminder && reminder.links) {
            const newLink = { ...reminder.links[index], name: value };
            const links = [...reminder.links.slice(0, index), newLink, ...reminder.links.slice(index + 1)];
            setReminder(
                {
                    ...reminder,
                    links
                }
            );
            setSubmitDisabled(false);
        }
    }

    const setLinkURL = (index: number, value: string) => {
        if (reminder && reminder.links) {
            const newLink = { ...reminder.links[index], url: value };
            const links = [...reminder.links.slice(0, index), newLink, ...reminder.links.slice(index + 1)];
            setReminder(
                {
                    ...reminder,
                    links
                }
            )
            setSubmitDisabled(false);
        }
    }


    const updateRulesetValue = (index: number, value?: string): ChangeResponse => {
        var _rules = [...reminder.rulesets!];
        var response = { error: null } as ChangeResponse;
        if (!value || value === "") {
            _rules[index] = "";
            setReminder({ ...reminder, rulesets: _rules });
        } else {
            setReminder({ ...reminder, rulesets: _rules });
            if (reminder.rulesets!.find(rule => rule === value)) {
                console.warn("DUPLICATE RULE");
                response = { error: "Rule already exists for this reminder." } as ChangeResponse;
            } else {
                _rules[index] = value;
            }
        }
        setSubmitDisabled(false);
        return response;
    }

    const addRule = () => {
        const newRules = reminder.rulesets ? [...reminder.rulesets, ""] : [""];
        setReminder(
            {
                ...reminder,
                rulesets: newRules,
            }
        );
    };


    const addDateProperty = () => {
        const newProp = { name: "", value: new Date() } as DateProperty;
        const newProperties = reminder.properties ? [...reminder.properties, newProp] : [newProp];
        setReminder(
            {
                ...reminder,
                properties: newProperties,
            }
        )
    }

    const addTextProperty = () => {
        const newProp = { name: "", value: "" } as StringProperty;
        const newProperties = reminder.properties ? [...reminder.properties, newProp] : [newProp];
        setReminder(
            {
                ...reminder,
                properties: newProperties,
            }
        )
    }

    const addNumericProperty = () => {
        const newProp = { name: "", value: 0 } as NumericProperty;
        const newProperties = reminder.properties ? [...reminder.properties, newProp] : [newProp];
        setReminder(
            {
                ...reminder,
                properties: newProperties,
            }
        )
    }



    React.useMemo(() => {
        if (teamContext && teamContext.data.ruleSets) {
            const _rules = teamContext.data.ruleSets.map((rule): RuleDropdownItem => {
                return {
                    id: rule._id,
                    label: rule.name,
                } as RuleDropdownItem;
            });
            setRules(_rules);
        }
    }, [teamContext]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{!reminder || !reminder._id ? "New Reminder" : "Reminder Details"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Item>
                            <Stack spacing={2}>
                                <TextField
                                    fullWidth={true}
                                    size="small"
                                    id="name"
                                    label="Name"
                                    value={reminder ? reminder.name : ""}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setName(event.target.value);
                                    }}
                                />

                                <TextField
                                    fullWidth={true}
                                    size="small"
                                    id="description"
                                    label="Description [optional]"
                                    multiline
                                    rows={3}
                                    value={reminder && reminder.description ? reminder.description : ""}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setDescription(event.target.value);
                                    }}
                                />

                            </Stack>
                        </Item>
                    </Grid>

                    {/* =================================  Properties  ================================= */}
                    <Grid item xs={12}>
                        <Item>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: "left" }}>
                                    <ButtonGroup>
                                        <Tooltip title="Add a Date property to reminder">
                                            <Button onClick={addDateProperty} size="small" aria-label="Add date property to reminder" variant="contained" color='primary' endIcon={<LibraryAddTwoToneIcon />}>Add Date</Button>
                                        </Tooltip>
                                        <Tooltip title="Add a Text property to reminder">
                                            <Button onClick={addTextProperty} size="small" aria-label="Add text property to reminder" variant="contained" color='primary' endIcon={<LibraryAddTwoToneIcon />}>Add Text</Button>
                                        </Tooltip>
                                        <Tooltip title="Add a Numeric property to reminder">
                                            <Button onClick={addNumericProperty} size="small" aria-label="Add numeric property to reminder" variant="contained" color='primary' endIcon={<LibraryAddTwoToneIcon />}>Add Numeric</Button>
                                        </Tooltip>
                                    </ButtonGroup>
                                </Grid>
                                {reminder.properties && reminder.properties.map((property, index) => (
                                    <Grid item xs={12} key={index} >
                                        <Stack direction="row" spacing={2}>
                                            <PropertyEditor setProperty={setProperty} property={property} index={index} />
                                            <Tooltip title="Remove rule from reminder">
                                                <IconButton aria-label="Remove rule from reminder" onClick={() => { removeProperty(index); }}>
                                                    <HighlightOffTwoToneIcon color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </Grid>

                                ))}
                            </Grid>
                        </Item>


                        {/* =================================  Rules  ================================= */}
                        <Grid item xs={12}>
                            <Item>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sx={{ textAlign: "left" }}>
                                        <Tooltip title="Add rule to reminder">
                                            <Button onClick={addRule} size="small" aria-label="Add rule to reminder" variant="contained" color='primary' endIcon={<LibraryAddTwoToneIcon />}>Add Rule</Button>
                                        </Tooltip>
                                    </Grid>
                                    {reminder.rulesets && reminder.rulesets.map((ruleset, index) => (
                                        <Grid item xs={12} key={index} >
                                            <Stack direction="row" spacing={2}>
                                                <RulesComboBox rules={rules} setValue={updateRulesetValue} value={ruleset} index={index} />
                                                <Tooltip title="Remove rule from reminder">
                                                    <IconButton aria-label="Remove rule from reminder" onClick={() => { removeRule(index); }}>
                                                        <HighlightOffTwoToneIcon color='primary' />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </Grid>

                                    ))}
                                </Grid>
                            </Item>
                        </Grid>

                        {/* =================================  Links  ================================= */}
                        <Grid item xs={12}>
                            <Item>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sx={{ textAlign: "left" }}>
                                        <Tooltip title="Add link to reminder">
                                            <Button onClick={addLink} size="small" aria-label="Add link to reminder" variant="contained" color='primary' endIcon={<LibraryAddTwoToneIcon />}>Add Link</Button>
                                        </Tooltip>
                                    </Grid>
                                    {reminder.links && reminder.links.map((link, linkIndex) => (
                                        <Grid item xs={12} key={linkIndex} >
                                            <Stack direction="row" spacing={2}>
                                                <TextField
                                                    fullWidth={true}
                                                    size="small"
                                                    id="link-name"
                                                    label="Name"
                                                    value={link && link.name ? link.name : ""}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setLinkName(linkIndex, event.target.value);
                                                    }}
                                                />
                                                <TextField
                                                    fullWidth={true}
                                                    size="small"
                                                    id="link-url"
                                                    label="URL"
                                                    value={link && link.url ? link.url : ""}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setLinkURL(linkIndex, event.target.value);
                                                    }}
                                                />
                                                <Tooltip title="Remove link from reminder">
                                                    <IconButton aria-label="Remove link from reminder" onClick={() => { removeLink(linkIndex); }}>
                                                        <HighlightOffTwoToneIcon color='primary' />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </Grid>

                                    ))}
                                </Grid>
                            </Item>
                        </Grid>

                        {/* =================================  Error  ================================= */}
                        <Collapse in={showError}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setShowError(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                <AlertTitle>Error</AlertTitle>
                                {error}
                            </Alert>
                        </Collapse>
                    </Grid>
                </Grid>
            </DialogContent >
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <SubmitButton
                    label="Save"
                    clickHandler={handleFormSubmit}
                    loading={loading}
                    setLoading={setLoading}
                    disabled={submitDisabled}
                />
            </DialogActions>
        </Dialog >
    );
}
