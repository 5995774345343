import * as React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import IconButton from '@mui/material/IconButton';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import MidoLogo from '../assets/logo.svg';
import { Tooltip, Typography } from '@mui/material';
import { CurrentUserContext } from '../contexts/UserProvider';
import { colors } from '../utils';


type Props = {
    children?: React.ReactNode;
    drawerOpen: boolean;
    setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;

};

function Header({ drawerOpen, setDrawerOpen }: Props) {
    const { logout } = useAuth0();
    const theme = useTheme();
    const big = useMediaQuery(theme.breakpoints.up('md'));
    const userContext = React.useContext(CurrentUserContext);

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL,
            },
        });
    };


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    }

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}><Link to="/user/profile" className='menu-link'>Profile</Link></MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleMenuClose}><Link to="/user/profile" className='menu-link'>Profile</Link></MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    );

    return (
        <AppBar
            position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, height: "120dp" }}
            color="primary"
            elevation={5}
        >
            <Toolbar sx={{ flexWrap: 'wrap' }}>
                <Tooltip title="Hide sidebar">
                    <IconButton onClick={() => toggleDrawer()} sx={{ marginLeft: 0, marginRight: "10px", display: { xs: 'none', md: 'flex' } }}>
                        {drawerOpen ? (
                            <MenuOpenIcon color='secondary' />
                        ) : (
                            <MenuIcon color='secondary' />
                        )}
                    </IconButton>
                </Tooltip>
                <img src={MidoLogo} alt="MIDO Logo" width={big ? "200dp" : "100dp"} />
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Typography sx={{color: colors['GhostWhite']}}>{userContext && userContext.userData && userContext.userData.user && userContext.userData.user.name}</Typography>
                </Box>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        sx={{color: colors['GhostWhite']}}
                    >
                        <AccountCircleTwoToneIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="small"
                        aria-label="show more"
                        aria-controls={mobileMenuId}
                        aria-haspopup="true"
                        onClick={handleMobileMenuOpen}
                        sx={{color: colors['GhostWhite']}}
                    >
                        <AccountCircleTwoToneIcon />
                    </IconButton>
                </Box>
                {renderMobileMenu}
                {renderMenu}
            </Toolbar>
        </AppBar>
    );

}


export default Header;