import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// import { CurrentUserContext } from '../contexts/UserProvider';


export default function GenerisHome() {
    // const userContext = React.useContext(CurrentUserContext);



    return (
        <React.Fragment>
            <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Generis Home
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary" component="p">
                    Need help, take a look at the topics below. If you still can't find what you are looking for trying opening a support ticket.
                </Typography>
            </Container>
        </React.Fragment>
    );
}
