
export const tiers = [
    {
      title: 'Free',
      price: '0',
      description: [
        '1 user',
        '10 reminders',
        'Help center access',
        'Email support',
      ],
      buttonText: 'Sign up for free',
      buttonVariant: 'outlined',
    },
    {
      title: 'Plus',
      price: '10',
      subheader: 'Most popular',
      description: [
        '10 users included',
        '1000 reminders',
        'Help center access',
        'Priority email support',
      ],
      buttonText: 'Get started',
      buttonVariant: 'contained',
    },
    {
      title: 'Pro',
      price: '20',
      description: [
        '40 users included',
        '10,000 reminders',
        'Help center access',
        'Priority email support',
      ],
      buttonText: 'Get started',
      buttonVariant: 'contained',
    },
    // {
    //   title: 'Enterprise',
    //   price: '30/user',
    //   description: [
    //     'unlimited users included',
    //     'unlimited reminders',
    //     'Help center access',
    //     'Phone & email support',
    //     'Service Level Agreement'
    //   ],
    //   buttonText: 'Contact us',
    //   buttonVariant: 'outlined',
    // },
  ];
  