import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { PageLoader } from '../components/page-loader';
import { useNavigate } from "react-router-dom";
import { Container, Typography } from "@mui/material";

export const CallbackPage: React.FC = () => {
    const { error, isLoading } = useAuth0();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!isLoading && !error) {
            navigate('/');
        }
    }, [navigate, error, isLoading]);


    if (isLoading) {
        return (
            <PageLoader />
        );
    }

    if (error) {
        return (
            <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
                <div className="content-layout">
                    <h1 id="page-title" className="content__title">
                        Error
                    </h1>
                    <div className="content__body">
                        <Typography variant="h6">{error.message}</Typography>
                        <Typography variant="body1">{error.name}</Typography>
                        <pre>{error.stack}</pre>
                    </div>
                </div>
            </Container>
        );
    }

    return (
        <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
            <div className="page-layout">
                Hi
            </div>
        </Container>
    );
};
