import React from "react";
import { TeamRemindersContext } from '../contexts/RemindersProvider';
import { SubmitButton } from "../components/submit-button";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Contact } from "../models/reminders";
// import { enumKeys } from "../utils";
import { Alert, AlertTitle, Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


type contactDetailProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    contact: Contact;
    setContact: React.Dispatch<React.SetStateAction<Contact>>;
};


export default function ContactDetails({ open, setOpen, contact, setContact }: contactDetailProps) {
    const [loading, setLoading] = React.useState(false);
    const [submitDisabled, setSubmitDisabled] = React.useState(true);
    const [showError, setShowError] = React.useState(false);
    const [error, setError] = React.useState("");
    const teamContext = React.useContext(TeamRemindersContext);



    const setName = (name: string) => {
        setContact(
            {
                ...contact,
                name: name,
            }
        )
        setSubmitDisabled(false);
    }
    const setEmail = (email: string) => {
        console.log("setting email")
        setContact(
            {
                ...contact,
                email: email,
            }
        )
        setSubmitDisabled(false);
    }

    const handleFormSubmit = async () => {
        setLoading(true);
        const response = await teamContext!.saveContact(contact);
        if (response.data) {
            setLoading(false);
            setSubmitDisabled(true);
            setOpen(false);
        } else {
            setError(response.error!.message as string);
            setShowError(true);
            setLoading(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setLoading(false);
    };


    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{!contact || !contact._id ? "New Contact" : "Contact Details"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    A contact can have multiple dates, contacts and rules associated with them.
                </DialogContentText>
                <FormControl fullWidth={true}>
                    <OutlinedInput
                        fullWidth={true}
                        id="contact-name"
                        value={contact.name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setName(event.target.value);
                        }}
                        aria-describedby="contact-name-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                    />
                    <FormHelperText id="contact-name-helper-text">Name</FormHelperText>
                </FormControl>
                <FormControl fullWidth={true}>
                    <OutlinedInput
                        fullWidth={true}
                        id="contact-email"
                        value={contact.email}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(event.target.value);
                        }}
                        aria-describedby="contact-email-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                    />
                    <FormHelperText id="contact-email-helper-text">Email</FormHelperText>
                </FormControl>

                <Collapse in={showError}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowError(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>
                </Collapse>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <SubmitButton
                    label="Save"
                    clickHandler={handleFormSubmit}
                    loading={loading}
                    setLoading={setLoading}
                    disabled={submitDisabled}
                />
            </DialogActions>
        </Dialog>
    );
}


