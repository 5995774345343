import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import ContactMailTwoToneIcon from '@mui/icons-material/ContactMailTwoTone';
import RuleTwoToneIcon from '@mui/icons-material/RuleTwoTone';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import WorkspacesTwoToneIcon from '@mui/icons-material/WorkspacesTwoTone';
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone';
import TroubleshootTwoToneIcon from '@mui/icons-material/TroubleshootTwoTone';
import { TeamSelector } from './team-selector';
import { CurrentUserContext } from '../contexts/UserProvider';
import { MIDO_ID } from '../models/teams';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import BottomSwipeDrawer from './bottom-drawer';
import { colors } from '../utils';

const buttonStyle = {
    alignItems: "flex-start",
    color: colors['GhostWhite'],
}

const adminButtonStyle = {
    alignItems: "flex-start",
    color: colors.Mirage,
}

type props = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    drawerWidth: number;
};



export default function Drawers({ open, drawerWidth, setOpen }: props) {
    const theme = useTheme();
    const big = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <React.Fragment>
            {!big && (
                <BottomSwipeDrawer open={open} setOpen={setOpen}>
                    <Navgation />
                </BottomSwipeDrawer>
            )}
            <Drawer variant="persistent" open={open} sx={{
                display: { xs: 'none', md: 'flex' },
                width: `${drawerWidth - 4}dp`,
            }}>
                <Toolbar />
                <Divider />
                <List sx={{ marginTop: "2px", backgroundColor: colors['GhostWhite'] }}>
                    <TeamSelector />
                </List>
                <Navgation />
            </Drawer>
        </React.Fragment >
    );
}


const Navgation = () => {
    const userContext = React.useContext(CurrentUserContext);

    return (
        <Box sx={{ overflow: 'auto' }}>
            <Divider />
            <List>
                {/* <ListItem disablePadding>
                    <Link to="/generis" className='menu-link'>
                        <ListItemButton sx={buttonStyle}>
                            <ListItemIcon><TodayTwoToneIcon sx={buttonStyle} /></ListItemIcon>
                            <ListItemText>Generis</ListItemText>
                        </ListItemButton>
                    </Link>
                </ListItem> */}
                <ListItem disablePadding>
                    <Link to="/contacts" className='menu-link'>
                        <ListItemButton sx={buttonStyle}>
                            <ListItemIcon><ContactMailTwoToneIcon sx={buttonStyle} /></ListItemIcon>
                            <ListItemText>Contacts</ListItemText>
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem disablePadding>
                    <Link to="/rulesets" className='menu-link'>
                        <ListItemButton sx={buttonStyle}>
                            <ListItemIcon><RuleTwoToneIcon sx={buttonStyle} /></ListItemIcon>
                            <ListItemText primary="RuleSets" />
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem disablePadding>
                    <Link to="/reminders" className='menu-link'>
                        <ListItemButton sx={buttonStyle}>
                            <ListItemIcon><TodayTwoToneIcon sx={buttonStyle} /></ListItemIcon>
                            <ListItemText>Reminders</ListItemText>
                        </ListItemButton>
                    </Link>
                </ListItem>
            </List>
            {/* <Divider />
            <List>
                <ListItem disablePadding>
                    <Link to="/teams" className='menu-link'>
                        <ListItemButton sx={buttonStyle}>
                            <ListItemIcon><WorkspacesTwoToneIcon sx={buttonStyle} /></ListItemIcon>
                            <ListItemText primary="Manage Teams" />
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem disablePadding>
                    <Link to="/support" className='menu-link'>
                        <ListItemButton sx={buttonStyle} >
                            <ListItemIcon><HelpTwoToneIcon sx={buttonStyle} /></ListItemIcon>
                            <ListItemText primary="Help" />
                        </ListItemButton>
                    </Link>
                </ListItem>
            </List>
            {userContext && userContext.currentTeam && userContext.currentTeam.team._id === MIDO_ID ? (
                <React.Fragment>
                    <Divider />
                    <List>
                        <ListItem disablePadding>
                            <Link to="/teams" className='menu-link'>
                                <ListItemButton sx={adminButtonStyle}>
                                    <ListItemIcon><ContentPasteSearchTwoToneIcon sx={adminButtonStyle} /></ListItemIcon>
                                    <ListItemText primary="Team Search" />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <ListItem disablePadding>
                            <Link to="/users" className='menu-link'>
                                <ListItemButton sx={adminButtonStyle}>
                                    <ListItemIcon><PersonSearchTwoToneIcon sx={adminButtonStyle} /></ListItemIcon>
                                    <ListItemText primary="User Search" />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <ListItem disablePadding>
                            <Link to="/dashboard" className='menu-link'>
                                <ListItemButton sx={adminButtonStyle}>
                                    <ListItemIcon><TroubleshootTwoToneIcon sx={adminButtonStyle} /></ListItemIcon>
                                    <ListItemText primary="Dashboard" />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <ListItem disablePadding>
                            <Link to="/requests" className='menu-link'>
                                <ListItemButton sx={adminButtonStyle}>
                                    <ListItemIcon><QuestionAnswerTwoToneIcon sx={adminButtonStyle} /></ListItemIcon>
                                    <ListItemText primary="User Requests" />
                                </ListItemButton>
                            </Link>
                        </ListItem> 
                    </List>
                </React.Fragment>
            ) : ""}*/}
        </Box>
    );
}