import './App.css';
import React from 'react';
import Pricing from './pages/pricing';
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import HomePage from './pages/home';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import PublicHeader from './components/public-header';
import Header from './components/authed-header';
import Footer from './components/footer';
import Features from './pages/features';
import { CallbackPage } from './pages/callback';
import { AuthenticationGuard } from './components/authentication-guard';
import Reminders from './pages/reminders';
import Contacts from './pages/contacts';
import TeamHome from './pages/teams';
import TeamDetails from './pages/team-details';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Drawers from './components/drawer';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useAuth0 } from "@auth0/auth0-react";
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';
import { UserContextProvider } from './contexts/UserProvider';
import { TeamRemindersProvider } from './contexts/RemindersProvider';
import RuleSets from './pages/rulesets';
import ProfilePage from './pages/profile';
import type { } from '@mui/x-data-grid/themeAugmentation';
import { styled } from '@mui/material/styles';
import { Box, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DeepLinkPage } from './pages/deep-link';
import { colors } from './utils';
import HelpPage from './pages/help';
import GenerisHome from './pages/generis-home';


const mdTheme = createTheme({
    palette: {
        primary: {
            main: colors.Mirage,
        },
        secondary: {
            main: colors.PigeonPost,
        },
        info: {
            main: colors.Nepal,
        },
        success: {
            main: colors.Sisal,
        },
        text: {
            primary: colors.Jet,
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    borderRadius: "20px",
                }
            }
        },
        MuiButtonGroup: {
            styleOverrides: {
                contained: {
                    borderRadius: "20px",
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: "20px",
                    backgroundColor: colors['GhostWhite'],
                    filter: `drop-shadow(6px 6px 4px ${colors.Jet})`,
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    width: "14em",
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: colors.Nepal,
                },
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                columnHeaderTitle: {
                    backgroundColor: colors.Mirage,
                    fontWeight: "bold",
                },
                columnHeaders: {
                    backgroundColor: colors.Mirage,
                    color: "#ffffff",
                    borderTopLeftRadius: "16px",
                    borderTopRightRadius: "16px",
                },
                root: {
                    border: 0,
                },
                toolbarContainer: {
                    border: 0,
                },
                footerContainer: {
                    backgroundColor: colors.Jet,
                    color: "#ffffff",
                    borderBottomLeftRadius: "16px",
                    borderBottomRightRadius: "16px",
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    color: "#ffffff",
                    fontWeight: "bold",
                },
                actions: {
                    color: "#ffffff",
                    fontWeight: "bold",
                },
                selectIcon: {
                    color: "#ffffff",
                },
            }
        }
    }
});

declare module '@mui/material/styles' {
    interface Palette {
        neutral: Palette['primary'];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        neutral?: PaletteOptions['primary'];
    }
}


interface AppContentProps {
    open: boolean;
}

export default function App() {
    return (
        <BrowserRouter>
            <Auth0ProviderWithNavigate>
                <ThemeProvider theme={mdTheme}>
                    <CssBaseline />
                    <GlobalStyles styles={{
                        ul: { margin: 0, padding: 0, listStyle: 'none' },
                        body: { backgroundColor: colors.PigeonPost },
                        AppContent: { backgroundColor: "white" },
                    }} />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <UserContextProvider>
                            <TeamRemindersProvider>
                                <Guts />
                            </TeamRemindersProvider>
                        </UserContextProvider>
                    </LocalizationProvider>
                </ThemeProvider>
            </Auth0ProviderWithNavigate>
        </BrowserRouter>

    );
}

const Guts = () => {
    const { user } = useAuth0();
    const theme = useTheme();
    const [drawerOpen, setDrawerOpen] = React.useState(true);

    const big = useMediaQuery(theme.breakpoints.up('md'));
    const drawerWidth: number = big ? 256 : 4;


    const AppContent = styled(Box, {
        shouldForwardProp: (prop) => prop !== 'open',
    })<AppContentProps>(({ theme }) => ({
        zIndex: theme.zIndex.drawer + 1,
        padding: 4,
        marginTop: 2,
        transition: theme.transitions.create(['width', 'margin', 'padding'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(drawerOpen && user && {
            marginLeft: drawerWidth - 34,
            paddingLeft: 34,
            width: `calc(100% - ${drawerWidth}dp)`,
            transition: theme.transitions.create(['width', 'margin', 'padding'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));


    return (
        <React.Fragment>
            {
                user ? (
                    <>
                        <Header drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
                        <Drawers open={drawerOpen} setOpen={setDrawerOpen} drawerWidth={drawerWidth} />
                    </>
                ) : (
                    <PublicHeader />
                )}
            <AppContent className='App' open={drawerOpen}>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/callback" element={<CallbackPage />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/features" element={<Features />} />

                    <Route path="/link/:linkType/:teamId/:objId" element={<AuthenticationGuard component={DeepLinkPage} />} />
                    
                    <Route path="/generis" element={<AuthenticationGuard component={GenerisHome} />} />
                    <Route path="/teams" element={<AuthenticationGuard component={TeamHome} />} />
                    <Route path="/teams/:teamId" element={<AuthenticationGuard component={TeamDetails} />} />
                    <Route path="/reminders" element={<AuthenticationGuard component={Reminders} />} />
                    <Route path="/reminders/:reminderId" element={<AuthenticationGuard component={Reminders} />} />
                    <Route path="/contacts" element={<AuthenticationGuard component={Contacts} />} />
                    <Route path="/rulesets" element={<AuthenticationGuard component={RuleSets} />} />
                    <Route path="/user/profile" element={<AuthenticationGuard component={ProfilePage} />} />
                    <Route path="/support" element={<AuthenticationGuard component={HelpPage} />} />
                </Routes>

            </AppContent>
            <Footer open={drawerOpen} />
        </React.Fragment>

    );
}

