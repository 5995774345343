import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Divider, Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LightbulbTwoToneIcon from '@mui/icons-material/LightbulbTwoTone';
import { CurrentUserContext } from '../contexts/UserProvider';
import { MemberTeam } from '../models/teams';
import IssueList from '../components/issues-list';

// import { Mermaid } from '../components/Mermaid';
// import ExampleChart from '../assets/example-chart';


export default function HelpPage() {
    const userContext = React.useContext(CurrentUserContext);
    const [team, setTeam] = React.useState<MemberTeam | null>(null);

    React.useMemo(() => {
        if (userContext && userContext.userData && userContext.userData.teams) {
            setTeam(userContext.currentTeam);
        }
    }, [userContext, setTeam]);

    const responseTime = (plan?: string): string => {
        switch (plan) {
            case "free":
                return "3 business days";
            case "plus":
                return "2 business day";
            case "pro":
                return "1 business days";
            case "enterprise":
                return "4 hours";
            default:
                return "4 business days"
        }
    }

    return (
        <React.Fragment>
            <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Help &amp; Support
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary" component="p">
                    Need help, take a look at the topics below. If you still can't find what you are looking for trying opening a support ticket.
                </Typography>
                {/* <Mermaid text={ExampleChart} /> */}
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    <Grid item xs={12} md={6}>
                        <Typography component="h5" variant="h5" align="left" color="text.primary" gutterBottom>Help Topics</Typography>
                        <List dense>
                            <ListItem>
                                <ListItemIcon><LightbulbTwoToneIcon /></ListItemIcon>
                                <ListItemText primary="Concepts" secondary="Concepts that make up the mido system" />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemIcon><LightbulbTwoToneIcon /></ListItemIcon>
                                <ListItemText primary="Contacts" secondary="Adding and updating contacts" />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemIcon><LightbulbTwoToneIcon /></ListItemIcon>
                                <ListItemText primary="Rules" secondary="Managing rules" />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemIcon><LightbulbTwoToneIcon /></ListItemIcon>
                                <ListItemText primary="Reminders" secondary="Managing reminders" />
                            </ListItem>
                        </List>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography component="h5" variant="h5" align="left" color="text.primary" gutterBottom>Support Tickets</Typography>
                        <Typography>For additional support you can submit a support ticket. With the <em>{team?.team.plan}</em> plan you can expect a response within {responseTime(team?.team.plan)}.</Typography>
                        <IssueList />
                    </Grid>

                </Grid>
            </Container>
        </React.Fragment>
    );
}
