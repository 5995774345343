import React from "react";
import { CurrentUserData, MemberTeam, Team, User } from "../models/teams";
import { useAuth0 } from '@auth0/auth0-react';
import { getCurrentUserDetails } from '../services/teams';
import { saveUser as _saveUser, saveTeam as _saveTeam } from '../services/teams';
import { ApiResponse } from "../models/base";


export interface CurrentUserContextType {
    userData: CurrentUserData | null;
    currentTeam: MemberTeam | null;
    loadUser: (reload: boolean) => Promise<void>;
    saveUser: (user: User) => Promise<void>;
    selectTeam: (team: MemberTeam) => void;
    saveTeam: (team: Team) => Promise<ApiResponse<Team>>;
    isAdminOfSelectedTeam: () => boolean;
    isSuperadminOfSelectedTeam: () => boolean;
};

export const CurrentUserContext = React.createContext<CurrentUserContextType | null>(null);

type props = {
    children: React.ReactNode;
};


export const UserContextProvider = function ({ children }: props) {
    const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const [userData, setUserData] = React.useState<CurrentUserData>({ user: null, teams: null });
    const [currentTeam, setCurrentTeam] = React.useState<MemberTeam | null>(null);

    const setDefaultWhenNoneExists = (parsedResponse: CurrentUserData): void => {
        if (!currentTeam && parsedResponse.teams && parsedResponse.teams!.length > 0) {
            selectTeam(parsedResponse!.teams![0]);
        }
    };

    const isAdminOfSelectedTeam = ():boolean =>  {
        if(currentTeam) {
            return (currentTeam!.type === "admin" || currentTeam!.type === "superuser");
        }
        return false;
    };

    const isSuperadminOfSelectedTeam = ():boolean => {
        if(currentTeam) {
            return currentTeam!.type === "superuser";
        }
        return false;
    };

    const saveUser = async (user: User) => {
        const token = await getAccessTokenSilently();
        await _saveUser(token, user);
    }

    const saveTeam = async (team: Team):Promise<ApiResponse<Team>> => {
        const token = await getAccessTokenSilently();
        return await _saveTeam(token, {
            _id: team._id,
            name: team.name,
            timezone: team.timezone,
        });
    }
    
    const loadUser = async (reload: boolean = false): Promise<void> => {
        const token = await getAccessTokenSilently();
        if (user && (!userData.user || reload)) {
            const response = await getCurrentUserDetails(token);
            if (response.data) {
                const parsedResponse: CurrentUserData = response.data as CurrentUserData;
                setUserData(parsedResponse);
                const teamId = localStorage.getItem(`user_team_selected`);
                var teamFound = false;
                if (teamId && (currentTeam === null || currentTeam.team._id !== teamId)) {
                    parsedResponse.teams!.forEach((team) => {
                        if (team.team._id === teamId) {
                            teamFound = true;
                            selectTeam(team);
                        }
                    });
                }
                if (!teamFound) {
                    setDefaultWhenNoneExists(parsedResponse);
                }
            } else {
                console.log(response.error);
            }
        }
    }

    if (user && !userData.user) {
        loadUser().then(res => {
        })
            .catch(error => {
                if (error.message.search("ssing Refresh Token")) {
                    // if this what we want? should we redirect to home? why is user not null?
                    loginWithRedirect({
                        appState: {
                        },
                        authorizationParams: {
                            prompt: "login",
                        },
                    });
                }
                console.log('ERROR:', error.message);
            });
    }

    const selectTeam = (team: MemberTeam) => {
        localStorage.setItem(`user_team_selected`, team.team._id);
        setCurrentTeam(team);
    }

    return (
        <CurrentUserContext.Provider value={{
            userData,
            currentTeam,
            loadUser,
            saveUser,
            saveTeam,
            selectTeam,
            isAdminOfSelectedTeam,
            isSuperadminOfSelectedTeam,
        }}>
            {children}
        </CurrentUserContext.Provider>
    );
}

