import { AxiosRequestConfig } from "axios";
import { callApi } from "./call-api";
import { ApiResponse } from "../models/base";
import { CurrentUserData, MemberTeam, Team, UpdateTeam, User } from "../models/teams";
import { tz } from "moment-timezone";
const apiServerUrl = process.env.REACT_APP_MIDO_API_URL;


export const getCurrentUserDetails = async (accessToken: string): Promise<ApiResponse<CurrentUserData>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/users/me`,
        method: "GET",
        timeout: 5000,
        headers: {
            "content-type": "application/json",
            Authorization: `bearer ${accessToken}`,
        },
    };
    return (await callApi({ config })) as ApiResponse<CurrentUserData>;
};


export const getUserTeams = async (
    accessToken: string,
    userId: string,
): Promise<ApiResponse<[MemberTeam]>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/users/${userId}/teams`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
    };

    return (await callApi({ config })) as ApiResponse<[MemberTeam]>;

};



export const createTeam = async (
    accessToken: string,
    teamName: string,
): Promise<ApiResponse<[Team]>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/teams`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        data: {
            name: teamName,
            plan: "free",
            timezone: tz.guess(),
        }
    };
    return (await callApi({ config })) as ApiResponse<[Team]>;

};


export const saveUser = async (
    accessToken: string,
    user: User,
): Promise<ApiResponse<[User]>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/users/${user._id}`,
        method: "PUT",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        data: user,
    };
    return (await callApi({ config })) as ApiResponse<[User]>;

};


export const saveTeam = async (
    accessToken: string,
    team: UpdateTeam,
): Promise<ApiResponse<Team>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/teams/${team._id}`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        data: team,
    };
    return (await callApi({ config })) as ApiResponse<Team>;

};

