import { TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { DateProperty, NumericProperty, StringProperty } from "../models/reminders";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


interface props {
    property: StringProperty | DateProperty | NumericProperty | null;
    setProperty: (index: number, property: StringProperty | DateProperty | NumericProperty) => void;
    index: number
}

export default function PropertyEditor({ property, setProperty, index }: props) {

    const setName = (name: string) => {
        if (property) {
            setProperty(index, {
                ...property,
                name
            });
        }
    };

    const setValue = (value: string | number | Date) => {
        if (property) {
            switch (typeof property.value) {
                case 'string':
                    setProperty(index, { ...property, value } as StringProperty);
                    break;
                case 'number':
                    setProperty(index, { ...property, value } as NumericProperty);
                    break;
                case 'object':
                    setProperty(index, { ...property, value } as DateProperty);
                    break;
            }
        }
    }

    return (
        <Stack direction="row" spacing={2}>
            <TextField
                fullWidth
                id="name"
                label="Name"
                value={property ? property.name : ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setName(event.target.value);
                }}
            />
            {property && (typeof property.value === "object" ) ? (
                <DatePicker
                    sx={{minWidth: "24ch"}}
                    label="Value"
                    value={property && property.value ? property.value : new Date()}
                    onChange={(newValue) => setValue(newValue?newValue:"")}
                />
            ) : (
                <TextField
                    fullWidth
                    id="value"
                    label="Value"
                    value={property ? property.value : ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setValue(event.target.value);
                    }}
                />
            )}
        </Stack>
    );
};
