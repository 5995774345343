import React from "react";
import Container from '@mui/material/Container';
import { useParams } from "react-router-dom";
import { Alert, AlertTitle, Button, Card, Chip, Collapse, FormControl, IconButton, InputLabel, MenuItem, Select, Skeleton, Stack, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import { CurrentUserContext } from '../contexts/UserProvider';
import { MemberTeam, Team } from "../models/teams";
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { tz } from "moment-timezone";
import { SubmitButton } from "../components/submit-button";
import { Item } from "../components/grid-item";
import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';

interface TeamInfo {
    membership: MemberTeam,
    team: Team,
    index: number,
}


const TeamDetails = () => {
    const teamContext = React.useContext(CurrentUserContext);
    const { teamId } = useParams();
    const [teamInfo, setTeamInfo] = React.useState<TeamInfo | null>(null);
    const [error, setError] = React.useState<string | null>(null);
    const [submitDisabled, setSubmitDisabled] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    const resetTeam = () => {
        loadTeam();
    }

    const handleFormSubmit = async () => {
        setLoading(true);
        const response = await teamContext!.saveTeam(teamInfo!.team);
        if (response.data) {
            setLoading(false);
            setSubmitDisabled(true);
        } else {
            setError(response.error!.message as string);
            // setShowError(true);
            setLoading(false);
        }
    }

    const setName = (name: string) => {
        if (teamInfo) {
            const newTeam = { ...teamInfo.team, name };
            setTeamInfo({
                ...teamInfo,
                team: newTeam,
            });
            setSubmitDisabled(false);
        }
    }

    const setTZ = (timezone: string) => {
        if (teamInfo) {
            const newTeam = { ...teamInfo.team, timezone };
            setTeamInfo({
                ...teamInfo,
                team: newTeam,
            });
            setSubmitDisabled(false);
        }
    }


    const loadTeam = React.useCallback(() => {
        if (teamContext && teamContext.userData && teamContext.userData.teams) {
            const teamIndex = teamContext.userData.teams.findIndex((team) => {
                return team.team._id === teamId;
            });
            if (teamIndex >= 0) {
                const team = teamContext.userData.teams[teamIndex];
                setTeamInfo({ membership: team, team: team.team, index: teamIndex });
            } else {
                setError("Team not found or you do not have access.")
            }
        }
    }, [teamContext, teamId, setTeamInfo]);

    React.useMemo(() => {
        loadTeam();
    }, [loadTeam]);


    return (
        <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
            <h1 id="page-title" className="content__title">Team {teamInfo ? teamInfo.team.name : ""}</h1>
            <Collapse in={error !== null}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setError(null);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    <AlertTitle>Error</AlertTitle>
                    {error}
                </Alert>
            </Collapse>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Item>
                            <Typography variant="h4" textAlign="left">Team Info</Typography>
                            <Collapse in={teamInfo === null}>
                                <Stack spacing={2} marginY={2}>
                                    <Skeleton variant="rounded" width="100%" height="40dp" />
                                    <Skeleton variant="rounded" width="100%" height="40dp" />
                                </Stack>
                            </Collapse>
                            <Collapse in={teamInfo !== null}>
                                <Stack spacing={2} marginY={2}>
                                    <TextField
                                        fullWidth={true}
                                        size="small"
                                        id="name"
                                        label="Team Name"
                                        value={teamInfo ? teamInfo.team.name : ""}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setName(event.target.value);
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                <Tooltip title="Team names can be changed but must be globally unique within MIDO">
                                                    <InfoTwoToneIcon color="disabled" />
                                                </Tooltip>
                                            </InputAdornment>,
                                        }}
                                    />

                                    <FormControl fullWidth>
                                        <InputLabel id="timezone-label">Timezone</InputLabel>
                                        <Select
                                            fullWidth
                                            size="small"
                                            labelId="timezone-label"
                                            id="match"
                                            onChange={(event) => {
                                                setTZ(event.target.value)
                                            }}
                                            value={teamInfo ? teamInfo.team.timezone : ""}
                                            label="Timezone"
                                            sx={{ textAlign: "left" }}
                                        >
                                            {tz.names().map((name, index) => (<MenuItem key={index} value={name}>{name}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                            </Collapse>
                            <Stack direction="row">
                                <Box sx={{ m: 1, position: 'relative' }}>
                                    <Button
                                        variant="contained"
                                        onClick={resetTeam}
                                        disabled={submitDisabled}>
                                        Reset
                                    </Button>
                                </Box>
                                <SubmitButton
                                    label="Save"
                                    clickHandler={handleFormSubmit}
                                    loading={loading}
                                    setLoading={setLoading}
                                    disabled={submitDisabled}
                                />
                            </Stack>
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Item>
                            <Typography variant="h4" textAlign="left">Plan Info</Typography>
                            <Toolbar>
                                <Typography textAlign="left" sx={{ fontWeight: "bold" }}>Current Plan</Typography>
                                <Card sx={{minWidth: "120dp"}} elevation={0}>
                                <Chip
                                    label= {teamInfo ? teamInfo?.team.plan : ""}
                                    icon={teamInfo ? <VerifiedUserTwoToneIcon />: <HelpTwoToneIcon/>}
                                    sx={{ marginX: 2, textAlign: "left"}}
                                />
                                </Card>
                                {/* <Button variant="outlined">
                                    Change Plan
                                </Button> */}
                            </Toolbar>
                        </Item>
                    </Grid>
                </Grid>
            </Box>

        </Container>
    );

}

export default TeamDetails;

