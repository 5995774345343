import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { PageLoader } from '../components/page-loader';
import { useNavigate } from "react-router-dom";
import { Container, Typography } from "@mui/material";
import { useParams } from 'react-router-dom';
import { CurrentUserContext } from "../contexts/UserProvider";


interface SimpleError {
    name: string;
    message: string;
}

export const DeepLinkPage: React.FC = () => {
    const { error, isLoading } = useAuth0();
    const navigate = useNavigate();
    const userContext = React.useContext(CurrentUserContext);
    const { linkType, teamId, objId } = useParams();
    const [_error, setError] = React.useState<SimpleError|null>(null);

    console.log(linkType, teamId, objId);

    React.useEffect(() => {
        if (!isLoading && !error && userContext?.userData?.teams) {
            console.log(" teams are here")
            const team = userContext.userData.teams.find((team) => team.team._id===teamId);
            if(team) {
                userContext.selectTeam(team);
                navigate(`/${linkType}/${objId}`);
            } else {
                setError({name: "Error Occurred", message: "The Object you are looking for is not available to you at this time."});
            }
        }
    }, [navigate, error, isLoading, userContext, linkType, teamId, objId]);


    if (isLoading) {
        return (
            <PageLoader />
        );
    }

    if (error || _error) {
        const displayError = _error ? _error : error!;
        return (
            <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
                <div className="content-layout">
                    <h1 id="page-title" className="content__title">
                        Error
                    </h1>
                    <div className="content__body">
                        <Typography variant="h6">{displayError.message}</Typography>
                        <Typography variant="body1">{displayError.name}</Typography>
                    </div>
                </div>
            </Container>
        );
    }

    return (
        <PageLoader />
    );
};
