import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, FormHelperText } from '@mui/material';
import { ChangeResponse } from '../models/base';

export interface ContactDropdownItem {
    id: string;
    label: string;
}

interface props {
    contacts: ContactDropdownItem[];
    setValue: (index: number, value?: string) => ChangeResponse;
    value?: string;
    index: number;
}

export function ContactsComboBox({ contacts, value, setValue, index }: props) {

    const [error, setError] = React.useState<string | null>(null);

    const getCachedContactWithId = React.useCallback((contactId?: string): ContactDropdownItem | undefined => {
        if (contacts && contactId) {
            return contacts.find(contact => contact.id === contactId);
        }
    }, [contacts]);

    return (
        <FormControl>
            <Autocomplete
                disablePortal
                value={getCachedContactWithId(value)}
                id="contacts-combo-box"
                options={contacts}
                size="small"
                onChange={(event, value, reason, details) => {
                    if (reason === "clear") {
                        setError(setValue(index, "").error);
                    }
                    if (value) {
                        setError(setValue(index, value.id).error);
                    } else {
                        setError(setValue(index, "").error);
                    }
                }}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Contact" />}
            />
            <FormHelperText error={error?true:false}>{error}</FormHelperText>
        </FormControl>
    );
}


