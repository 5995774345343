import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { TeamRemindersContext } from '../contexts/RemindersProvider';
import { CurrentUserContext } from '../contexts/UserProvider';
import {
    GridRenderCellParams,
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridRowParams,
    GridCallbackDetails,
} from '@mui/x-data-grid';
import { MuiEvent } from '@mui/x-data-grid';
import { ButtonGroup, Tooltip, Button, Collapse, Alert, IconButton, AlertTitle } from "@mui/material";
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import ControlPointTwoTone from '@mui/icons-material/ControlPointTwoTone';
import RuleSetDetails from './ruleset-detail';
import { RuleSet, Match, NotifyAction } from '../models/reminders';
import { useAuth0 } from '@auth0/auth0-react';
import { deleteRuleSet } from '../services/reminders';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


export default function RuleSets() {
    const { getAccessTokenSilently } = useAuth0();
    const userContext = React.useContext(CurrentUserContext);
    const teamContext = React.useContext(TeamRemindersContext);
    const [detailDialogShow, setDetailDialogShow] = React.useState(false);
    const [ruleSet, setRuleSet] = React.useState<RuleSet>({
        team: "",
        name: "",
        actions: [{ name: "notify action", type: "notify", contacts: [] } as NotifyAction],
        description: "",
        match: Match.ANY,
        rules: [],
    } as RuleSet);

    const [showError, setShowError] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>("");
    const [ruleSets, setRuleSets] = React.useState<RuleSet[]>([]);
    const theme = useTheme();
    const big = useMediaQuery(theme.breakpoints.up('md'));

    const handleDelete = async (RuleSetId: string) => {
        const token = await getAccessTokenSilently();
        const response = await deleteRuleSet(token, teamContext!.data.teamId, RuleSetId);
        teamContext!.loadRuleSets(true);
        if (response.error && response.statusCode >= 300) {
            setShowError(true);
            setError(response.error.message);
        }
    }

    function renderRowActions(
        params: GridRenderCellParams<{ id: string, name: string }, any, any>,
    ) {
        return (
            <ButtonGroup variant="contained" aria-label="team actions" color='primary'>
                <Tooltip title={"See " + params.row.name + " details"}>
                    <Button
                        size="small"
                        aria-label={"See " + params.row.name + " details"}
                        endIcon={<InfoTwoToneIcon />}
                        onClick={() => showDetailDialog(params.row.id)}
                    >
                        {big && "Details"}
                    </Button>
                </Tooltip>

                <Tooltip title={"delete " + params.row.name}>
                    <Button
                        size="small"
                        aria-label={"delete " + params.row.name}
                        endIcon={<HighlightOffTwoToneIcon />}
                        onClick={() => handleDelete(params.row.id)}
                    >
                        {big && "Remove"}
                    </Button>
                </Tooltip>
            </ButtonGroup>
        );
    }

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', flex: 1 },
        {
            field: 'id',
            minWidth: big ? 220: 70,
            headerName: "actions",
            renderCell: renderRowActions,
            hideable: false,
            valueGetter: (params) => params.row.name == null ? null : { id: params.row.id, name: params.row.name },
        },
    ];


    function EditToolbar() {
        const handleClick = () => {
            showDetailDialog(null);
        };

        return (
            <GridToolbarContainer sx={{ marginBottom: 1 }}>
                <Tooltip title="Create RuleSet">
                    <Button color="primary" variant='contained' startIcon={<ControlPointTwoTone />} onClick={handleClick}>
                        Create RuleSet
                    </Button>
                </Tooltip>
            </GridToolbarContainer>
        );
    }

    const showDetailDialog = (RuleSetId: string | null) => {
        if (RuleSetId !== null) {
            const _RuleSet = ruleSets.find((RuleSet) => RuleSet._id === RuleSetId);
            if (_RuleSet) {
                setRuleSet(_RuleSet);
            } else {
                setError("RuleSet not found");
                return;
            }
        } else {
            setRuleSet({
                team: "",
                name: "",
                actions: [{ name: "notify action", type: "notify", contacts: [] } as NotifyAction],
                description: "",
                match: Match.ANY,
                rules: [],
            } as RuleSet);
        }
        setDetailDialogShow(true);
    };

    const rowEditRequested = (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        showDetailDialog(params.row._id);
    };

    React.useMemo(() => {
        if (teamContext && teamContext.data.ruleSets) {
            const cleanRuleSets = teamContext.data.ruleSets.map((rule) => {
                return {
                    ...rule,
                    id: rule._id,
                }
            });
            setRuleSets(cleanRuleSets);
        }
    }, [teamContext, setRuleSets]);

    return (
        <React.Fragment>
            <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Rules for {userContext && userContext.currentTeam ? userContext.currentTeam.team.name : ""}
                </Typography>
                <Collapse in={showError}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowError(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>
                </Collapse>
                <DataGrid
                    rows={ruleSets}
                    columns={columns}
                    autoHeight
                    onRowDoubleClick={rowEditRequested}
                    pageSizeOptions={[5, 10, 25, 50]}
                    disableRowSelectionOnClick
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                        },
                    }}
                />
                <RuleSetDetails open={detailDialogShow} setOpen={setDetailDialogShow} ruleSet={ruleSet} setRuleSet={setRuleSet} />
            </Container>
        </React.Fragment >
    );
}
