import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, FormHelperText } from '@mui/material';
import { ChangeResponse } from '../models/base';


export interface RuleDropdownItem {
    id: string;
    label: string;
}


interface props {
    rules: RuleDropdownItem[];
    setValue: (index: number, value?: string) => ChangeResponse;
    value?: string;
    index: number;
}

export function RulesComboBox({ rules, value, setValue, index }: props) {

    const [changeResponse, setChangeResponse] = React.useState<ChangeResponse>({error: ""});
    const [isError, setIsError] = React.useState(false);
    

    const getCachedRuleWithId = React.useCallback((ruleId?: string): RuleDropdownItem | undefined => {
        if (rules && ruleId) {
            return rules.find(rule => rule.id === ruleId);
        }
        return {id: "", label: ""};
    }, [rules]);

    const handleChange = (value: RuleDropdownItem | null) => {
        var r:ChangeResponse = setValue(index, (value ? value?.id : ""));
        setChangeResponse(r);
        setIsError(r.error ? true : false);
}

    return (
        <FormControl>
            <Autocomplete
                disablePortal
                value={getCachedRuleWithId(value)}
                id="rules-combo-box"
                options={rules}
                size='small'
                onChange={(event, value, reason, details) => {
                    handleChange(value);
                }}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Rule" />}
            />
            <FormHelperText error={isError}>{changeResponse.error ? changeResponse.error : ""}</FormHelperText>
        </FormControl>
    );
}


