import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Calculating from './calculating';
import { colors } from '../utils';

type props = {
    label: string;
    clickHandler: () => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    disabled: boolean;
};


export const SubmitButton = function ({ label, clickHandler, loading, setLoading, disabled }: props) {
    const handleButtonClick = () => {
        if (!loading) {
            setLoading(true);
            clickHandler();
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
                sx={{
                    minWidth: (label.length) + 'em',
                    minHeight: '3em',
                    textAlign: 'center',
                    backgroundColor: loading ? colors.Nepal : colors.Mirage,
                    ":hover": {
                        backgroundColor: loading ? colors.Nepal : colors.PigeonPost,
                    },
                }}
                variant="contained"
                disabled={disabled}
                onClick={handleButtonClick}
            >
                {!loading && label}
                {loading && (
                    <Calculating />
                )}
            </Button>
        </Box>
    );
}