import * as React from 'react';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { TeamSelector } from './team-selector';
import Fab from '@mui/material/Fab';
import MenuIcon from '@mui/icons-material/Menu';
import { isMobile } from 'detect-touch-device';
import { colors } from '../utils';

const drawerBleeding = 20;

interface Props {
    children?: React.ReactNode;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Root = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor: colors.Nepal,
}));

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: colors.Nepal,
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: colors.PigeonPost,
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));


export default function BottomSwipeDrawer(props: Props) {
    const { open, setOpen, children } = props;

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    return (
        <Root sx={{
            display: { xs: 'flex', md: 'none' },
        }}>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(60% - ${drawerBleeding}px)`,
                        overflow: 'visible',
                    },
                }}
            />

            <Fab
                onClick={toggleDrawer(!open)}
                color="secondary"
                sx={{
                    display: isMobile ? 'none' : 'flex',
                    position: 'absolute',
                    bottom: (theme) => theme.spacing(3),
                    right: (theme) => theme.spacing(3),
                }}
            >
                <MenuIcon />
            </Fab>
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <StyledBox
                    sx={{
                        position: 'absolute',
                        top: -drawerBleeding,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                        backgroundColor: colors.Nepal,
                        height: drawerBleeding,
                    }}
                >
                    <Puller />
                    <StyledBox
                        sx={{
                            px: 2,
                            pb: 2,
                            marginTop: 4,
                        }}
                    >
                        <TeamSelector />
                    </StyledBox>
                </StyledBox>
                <StyledBox
                    sx={{
                        px: 2,
                        pb: 2,
                        height: '100%',
                        overflow: 'auto',
                        marginTop: 10,
                    }}
                >
                    {children}
                </StyledBox>
            </SwipeableDrawer>
        </Root>
    );
}